import {Checkbox, FormControlLabel, Stack, TextField} from '@mui/material';
import {Incident, NpvFileType} from './types';
import React, {useCallback} from 'react';
import {FILE_UPLOAD_MAX_SIZE_MB} from 'utils';
import {FileWithProgress, FileWithUniqueId} from '@features/files/types';
import {useForceUpdate} from 'utils/hooks/useForceUpdate';
import {AxiosProgressEvent} from 'axios';
import {IncidentService} from '../../services/IncidentService/IncidentService';
import {setNotification} from 'slices/notifications/notificationsSlice';
import {useDispatch} from 'react-redux';
import {filterFiles} from '@features/files/utils';
import {NpvEditFileRow} from './NpvEditFileRow';

export type FileWithProgressAndType = FileWithProgress & {
    id?: string;
    npvType?: NpvFileType;
};

type NpvFilesEditProps = {
    data: Incident;
    filesToAdd: React.MutableRefObject<FileWithProgressAndType[]>;
    filesToDelete: React.MutableRefObject<string[]>;
    comment: string;
    setComment: (newComment: string) => void;
    isWithoutAct: boolean;
    setWithoutAct: (newValue: boolean) => void;
};
export const NpvFilesEdit: React.FC<NpvFilesEditProps> = ({
    data,
    filesToAdd,
    filesToDelete,
    comment,
    setComment,
    setWithoutAct,
    isWithoutAct,
}) => {
    const forceUpdate = useForceUpdate();
    const dispatch = useDispatch();
    const onUploadProgress: (
        file: FileWithUniqueId,
    ) => (progressEvent: AxiosProgressEvent) => void = useCallback(
        (file: FileWithUniqueId) => (progressEvent) => {
            const uploadingFile = filesToAdd.current.find((f) => f.uniqueId === file.uniqueId);

            if (uploadingFile) {
                const progress = progressEvent.loaded / (progressEvent.total || 1);
                uploadingFile.progress = progress;
            }
            forceUpdate();
        },
        [filesToAdd, forceUpdate],
    );
    const uploadSingleFile = useCallback(
        (file: FileWithUniqueId, type: NpvFileType) => {
            (file as FileWithProgressAndType).npvType = type;
            if (file.size < FILE_UPLOAD_MAX_SIZE_MB * 1024 * 1024) {
                const abortController = new AbortController();
                const formData = new FormData();
                formData.append('file', file, file.name);
                (file as FileWithProgressAndType).abortController = abortController;
                const newFiles = [file, ...filesToAdd.current];
                filesToAdd.current = newFiles;

                return IncidentService.createFile(
                    formData,
                    {},
                    onUploadProgress(file),
                    abortController.signal,
                ).then((result) => {
                    const uploadingFile = filesToAdd.current.find(
                        (f) => f.uniqueId === file.uniqueId,
                    );
                    if (uploadingFile) {
                        uploadingFile.id = result.data.id;
                    }
                    forceUpdate();
                });
            } else {
                dispatch(
                    setNotification({
                        text: 'Размер файла не должен превышать 20 Mb',
                        variant: 'warning',
                    }),
                );
                (file as FileWithProgressAndType).error = 'Файл слишком большой';
                const newFiles = [file, ...filesToAdd.current];
                filesToAdd.current = newFiles;
                forceUpdate();
            }
        },
        [dispatch, filesToAdd, forceUpdate, onUploadProgress],
    );

    const handleDelete = useCallback(
        (file: FileWithProgressAndType) => () => {
            filesToAdd.current = filesToAdd.current.filter(filterFiles(file));
            file?.abortController?.abort();
            forceUpdate();
        },
        [filesToAdd, forceUpdate],
    );

    const handleUpload = useCallback(
        (files: FileList, type: NpvFileType) => {
            for (let i = 0; i < files.length; i++) {
                const fileWithId = files[i];
                (fileWithId as FileWithUniqueId).uniqueId = Math.floor(Math.random() * 1000000) + 1;
                uploadSingleFile(fileWithId as FileWithUniqueId, type);
            }
        },
        [uploadSingleFile],
    );

    const handleFileInputChange: (type: NpvFileType) => React.ChangeEventHandler<HTMLInputElement> =
        useCallback(
            (type: NpvFileType) => (event) => {
                if (event.target.files !== null) {
                    handleUpload(event.target.files, type);
                }
            },
            [handleUpload],
        );
    const onDelete = useCallback(
        (id: string) => {
            filesToDelete.current = [id, ...filesToDelete.current];
            forceUpdate();
            return Promise.resolve();
        },
        [filesToDelete, forceUpdate],
    );
    return (
        <>
            <Stack>
                <FormControlLabel
                    label='Без акта'
                    control={
                        <Checkbox
                            size='medium'
                            checked={isWithoutAct}
                            onChange={() => setWithoutAct(!isWithoutAct)}
                        />
                    }
                />
                {isWithoutAct && (
                    <Stack gap='16px'>
                        <TextField
                            variant='standard'
                            value={comment}
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                            minRows={1}
                            maxRows={4}
                            label='Комментарий'
                            multiline
                            inputProps={{
                                maxLength: 250,
                            }}
                        />
                        <NpvEditFileRow
                            type={NpvFileType.PHOTO}
                            files={data.photos}
                            filesToAdd={filesToAdd}
                            filesToDelete={filesToDelete}
                            handleDelete={handleDelete}
                            handleFileInputChange={handleFileInputChange}
                            onDelete={onDelete}
                        />
                    </Stack>
                )}
            </Stack>
            {!isWithoutAct &&
                Object.values(NpvFileType).map((type) => (
                    <NpvEditFileRow
                        key={type}
                        type={type}
                        files={type === NpvFileType.PHOTO ? data.photos : data.files}
                        filesToAdd={filesToAdd}
                        filesToDelete={filesToDelete}
                        handleDelete={handleDelete}
                        handleFileInputChange={handleFileInputChange}
                        onDelete={onDelete}
                    />
                ))}
        </>
    );
};
