import api from '../../utils/api';
import {BaseData, PageData, ParamsType, ServerMenu} from '../../utils';
import {FILE_URL_PART} from '../../constants';
import {MenuType} from 'constants/menu';

export class TableSchemaService {
    private static instance: TableSchemaService;

    public static getInstance() {
        if (!TableSchemaService.instance) {
            TableSchemaService.instance = new TableSchemaService();
        }

        return TableSchemaService.instance;
    }

    private constructor() {}

    public readonly filesHrefPart = FILE_URL_PART;

    public getGeneralSchema() {
        return this.getSchema('/table/schema');
    }

    public getPageSchema(page: string) {
        return this.getSchema(`/table/schema/${page}`);
    }

    public getMenu(type: MenuType): Promise<ServerMenu> {
        return this.getSchema(`/menus?full=true&type=${type}`);
    }

    public getSubMenu(pathname: string): Promise<ServerMenu> {
        return this.getSchema(pathname);
    }

    private getSchema<Type>(url: string): Promise<Type> {
        return api.get<Type>(url);
    }

    public getDictionary({code, params}: {code: string; params: ParamsType}): Promise<PageData<BaseData>> {
        return api.get<PageData<BaseData>>(`/dict?code=${code}`, params);
    }

    public getProjectDictionary() {
        return api.get<PageData<BaseData>>('/wells/project');
    }

    public getContractDictionary() {
        return api.get<PageData<BaseData>>('/wells/contract');
    }

    public getGuiltySideDictionary() {
        return api.get<PageData<BaseData>>('/incidents/analyse/guilty-side/dict');
    }
}
