import {useEffect, useMemo, useState} from 'react';
import {DashboardService} from '../../services/Dashboard/DashboardService';
import {RouteParams, invokeAsync} from '../../utils';
import {useLoading} from '../../utils/hooks/useLoading';
import {useParams} from 'react-router-dom';
import {ChartFilter, ChartProps} from './types';

const instance = DashboardService.getInstance();

export const useChart = (
    code: string,
    filters: ChartFilter,
    wellId?: string | string[],
    forceReload?: number,
): [ChartProps | null, boolean] => {
    const params = useParams<RouteParams>();
    const {loading, setLoading} = useLoading();
    const [chartData, setData] = useState<ChartProps | null>(null);

    const currentWellId = wellId || params.wellId;

    useEffect(() => {
        void invokeAsync(async () => {
            if (currentWellId) {
                const wellIdArr = Array.isArray(currentWellId) ? currentWellId : [currentWellId];
                const filterParams = {...filters, wellId: wellIdArr};
                const response = await instance.getChart(code, filterParams);
                setData(response);
            }
            if (filters.field && Array.isArray(filters['field'])) {
                const filterParams = {...filters, field: [filters['field'][0] as string]};
                const response = await instance.getChart(code, filterParams);
                setData(response);
            }
        }, setLoading);
    }, [setLoading, currentWellId, code, filters, forceReload]);

    return useMemo(() => [chartData, loading], [chartData, loading]);
};
