import {useEffect, useMemo, useState} from 'react';
import {DashboardService} from '@services/Dashboard/DashboardService';
import {invokeAsync, RouteParams} from '../../utils';
import {useParams} from 'react-router-dom';
import {ChartCode, ChartItem} from 'components/Chart/types';

const instance = DashboardService.getInstance();

const orderCharts = (chartData: ChartItem[]): ChartItem[] => {
    const chartsOrder = {
        [ChartCode.GGD_FACT]: 1,
        [ChartCode.GGD_FACT_BY_WELLS]: 1,
        [ChartCode.BUILDING_DATES_BY_STAGE]: 2,
        [ChartCode.DURATION_BY_WORK_CATEGORY]: 3,
        [ChartCode.DURATION_BY_WORK_TYPE]: 4,
        [ChartCode.DRILLING_PARAMETERS]: 5,
        [ChartCode.WELL_OPERATIONS_PV_NPV]: 6,
        [ChartCode.TIME_NPV]: 7,
        [ChartCode.OPERATION_WORK_CATEGORY]: 8,
        [ChartCode.WASTE_GENERATION_ACCOUNTING_PLAN_FACT]: 9,
        [ChartCode.BUILDING_STAGE]: 10,
        [ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS]: 11,
        [ChartCode.MTR_CONSUMPTIONS]: 12,
        [ChartCode.COST_BY_DAY]: 1,
        [ChartCode.TRAJECTORY_VERTICAL_SLICE]: 13,
        [ChartCode.TRAJECTORY_HORIZONTAL_SLICE]: 14,
        [ChartCode.ASSIMILATION_OPERATIONS_PV_NPV]: 15,
        [ChartCode.COST_BY_SERVICE]: 17,
        [ChartCode.FIELD_OPERATIONS_PV_NPV]: 18,
        [ChartCode.PV_NPV_BY_WELL]: 19,
        [ChartCode.NPV_BY_WELL]: 20,
        [ChartCode.TIME_DISTRIBUTION]: 21,
        [ChartCode.DAYS_COUNT_BY_1000M]: 22,
        [ChartCode.PENETRATION_SPEED_INFO_BY_WELL]: 23,
        [ChartCode.STAGE_DURATION]: 24,
        [ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS]: 25,
        [ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS]: 26,
        [ChartCode.KNBK_PARAMETERS]: 27,
        [ChartCode.KNBK_SINKING]: 28,
        [ChartCode.KNBK_PARAMETERS_REFERENCE]: 29,
        [ChartCode.KNBK_SINKING_REFERENCE]: 30,
        [ChartCode.OT_PB_OOS_BY_WELL]: 31,
        [ChartCode.OT_PB_OOS_BY_WELLS]: 32,
        [ChartCode.GEOLOGICAL_JOURNAL]: 33,
        [ChartCode.OT_PB_OOS_BY_WELL_AND_STAGE]: 34,
        [ChartCode.OT_PB_OOS_BY_WELLS_AND_STAGE]: 35,
    };

    const result = chartData.sort((a, b) => {
        const aOrder = chartsOrder[a.code];
        const bOrder = chartsOrder[b.code];
        return aOrder - bOrder;
    });

    return result;
};

export enum ChartListType {
    dashboard,
    prices,
    common_info_by_well,
    common_info,
    stages,
    trajectory,
    knbk
}

const computeMethod = (listType: ChartListType) => {
    if (listType === ChartListType.dashboard) return instance.getChartsList;
    if (listType === ChartListType.prices) return instance.getPricesChartList;
    if (listType === ChartListType.stages) return instance.getStageAnalyseChartList;
    if (listType === ChartListType.trajectory) return instance.getTrajectoryChartList;
    if (listType === ChartListType.common_info_by_well) return instance.getChartsByWellCommonInfo;
    if (listType === ChartListType.knbk) return instance.getKnbkChartList;
    return instance.getChartsForCommonInfoPage;
};

export const useChartsList = (
    wellId?: string,
    listType: ChartListType = ChartListType.dashboard,
): [ChartItem[]] => {
    const params = useParams<RouteParams>();
    const dataWellId = wellId || params.wellId;
    const [data, setData] = useState<ChartItem[]>([]);

    useEffect(() => {
        void invokeAsync(async () => {
            if (dataWellId) {
                setData([]);
                const method = computeMethod(listType);
                const response = await method(Number(dataWellId));
                const sortedData = orderCharts(response.charts);
                setData(sortedData);
            } else {
                setData([]);
                const response = await instance.getChartsForCommonInfoPage();
                const sortedData = orderCharts(response.charts);
                setData(sortedData);
            }
        });
    }, [dataWellId, listType]);

    return useMemo(() => [data], [data]);
};
