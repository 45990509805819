import {useSnackbar} from 'notistack';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {selectNotification} from 'slices';

export const Notifications = () => {
    const {enqueueSnackbar} = useSnackbar();
    const notification = useSelector(selectNotification);

    useEffect(() => {
        if (notification) {
            enqueueSnackbar(notification.text, {
                variant: notification.variant,
                className: notification.className,
            });
        }
    }, [notification, enqueueSnackbar]);

    return null;
};
