import {Autocomplete, TextField} from '@mui/material';

import {Option} from '@pages/AdministrationPage/types';

type FiltersProps = {
    filters: {label: string; key: string}[];
    setFilters: (filter: Record<string, string | null>) => void;
    options: Record<string, Option[]>;
    outerValue?: Record<string, string | null>;
};

const Filters = ({filters, setFilters, options}: FiltersProps) => {
    return (
        <>
            {filters.map(({label, key}) => (
                <Autocomplete
                    sx={{width: '10%'}}
                    disablePortal
                    key={key}
                    getOptionKey={(opt) => opt.value}
                    getOptionLabel={(opt) => opt.label}
                    isOptionEqualToValue={(opt, val) => opt.value === val.value}
                    options={options[key] || []}
                    onChange={(event, value) => setFilters({[key]: value?.value || null})}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={label}
                            size='small'
                            variant='standard'
                        />
                    )}
                />
            ))}
        </>
    );
};

export default Filters;
