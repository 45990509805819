import {useLocation} from 'react-router-dom';

import {Routes} from 'constants/routes';

export enum AutoDrillingTabs {
    CANDLE_CONSTRUCTION = 'candle-construction',
    PRO_MASTER = 'pro-master',
}

export const useTabsAutoDrilling = () => {
    const location = useLocation();

    const tabs = [
        {
            key: AutoDrillingTabs.CANDLE_CONSTRUCTION,
            label: 'Конструктор свечи',
            url: `${Routes.AutoDrilling}/${AutoDrillingTabs.CANDLE_CONSTRUCTION}`,
        },
        {
            key: AutoDrillingTabs.PRO_MASTER,
            label: 'АКБ',
            url: `${Routes.AutoDrilling}/${AutoDrillingTabs.PRO_MASTER}`,
        },
    ];

    return {tabs, currentValue: location.pathname};
};
