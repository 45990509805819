import {useEffect} from 'react';
import {RouteParams, invokeAsync} from '../../utils';
import {useDispatch} from 'react-redux';
import {setDictionary, deleteDictionary} from 'slices/dictionariesSlice';
import {createDictionaryParams, getDictionary} from './usePageSchema';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {selectDictionaries} from 'slices';

type UseDictionaryProps = {
    code?: string;
    key?: string;
    need?: boolean;
    fromStore?: boolean;
};

export const useDictionary = ({code, key, need = true, fromStore = false}: UseDictionaryProps) => {
    const params = useParams<RouteParams>();
    const dispatch = useDispatch();
    const dictionaries = useSelector(selectDictionaries);
    const dictionary = code ? dictionaries[code] : [];

    useEffect(() => {
        if (need && code) {
            if (fromStore && dictionary && !!dictionary.length) {
                return;
            }
            invokeAsync(async () => {
                dispatch(deleteDictionary({code}));
                const dictionaryParams = createDictionaryParams(params);
                const data = await getDictionary({code, params: {key, ...dictionaryParams}});
                dispatch(setDictionary({[code]: data}));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, need, code, key, params, fromStore]);
};
