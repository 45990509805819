import {FormControlLabel, IconButton, Stack, Switch, Typography, useTheme} from '@mui/material';
import {Chart, ChartComponentProps, ChartFilter, ChartItem, ChartProps} from '@ui/Chart';
import {useCallback, useMemo, useState} from 'react';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import {getChartContainerStyles} from 'utils/functions/getChartContainerStyles';
import {ReactComponent as ZoomOutOutlinedIcon} from '../../assets/icons/zoomOut.svg';
import {LineOptions as G2plotConfig} from '@antv/g2plot';

type GgdChartProps = Partial<ChartComponentProps> & {
    chartData: ChartItem;
    config?: {
        withIsOnlyDrilling?: boolean;
        withFullScreen?: boolean;
        withOpacity?: boolean;
    };
};

export const GgdChart = ({
    chartData,
    config: componentConfig,
    customConfig,
    ...otherProps
}: GgdChartProps) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const theme = useTheme();
    const [key, setKey] = useState(0); // Используется для форсирования перерисовки

    const handleResetClick = () => {
        setKey(key + 1); // Обновление ключа для форсирования перерисовки
    };

    const [outerFilters, setOuterFilters] = useState({isOnlyDrilling: false, isShowLKS: false});
    const formattedFilters = useMemo(() => {
        const flags: string[] = [];
        if (outerFilters.isOnlyDrilling) {
            flags.push('isOnlyDrilling');
        }
        if (outerFilters.isShowLKS) {
            flags.push('isShowLKS');
        }
        if (flags.length === 0) {
            return {} as ChartFilter;
        }
        return {flags};
    }, [outerFilters]);

    const customNewConfig = useCallback(
        (config: Omit<G2plotConfig, 'tooltip' | 'data'>, values?: ChartProps): G2plotConfig => {
            const newConfig = customConfig ? customConfig(config, values) : config;
            // @ts-ignore
            newConfig.lineStyle = (d: {type: string}) => {
                const {type} = d;

                if (type.includes('ЛКС'))
                    return {
                        lineDash: [4, 5],
                        shadowColor: theme.palette.mode === 'light' ? '' : 'rgba(255,255,255, 0.5)',
                        shadowBlur: 8,
                        lineWidth: 3,
                    };
                if (
                    componentConfig?.withOpacity &&
                    outerFilters.isShowLKS &&
                    !type.includes('ЛКС')
                ) {
                    return {opacity: 0.45};
                }
                return {};
            };
            // @ts-ignore
            newConfig.legend = {
                ...newConfig.legend,
                // @ts-ignore
                marker: (text) => {
                    if (text.includes('ЛКС')) {
                        return {
                            style: {
                                lineDash: [4, 5],
                            },
                        };
                    }
                },
            };
            return newConfig as G2plotConfig;
        },
        [componentConfig?.withOpacity, customConfig, outerFilters.isShowLKS, theme.palette.mode],
    );

    return (
        <Stack
            // @ts-ignore
            sx={{
                ...getChartContainerStyles(fullScreen),
            }}
            gap={1}
            height='100%'
        >
            <Stack
                direction='row'
                justifyContent='flex-end'
                alignItems='center'
                sx={{
                    position: fullScreen ? undefined : 'relative',
                }}
                gap={1}
            >
                <Typography
                    variant='h3'
                    sx={{mr: 'auto'}}
                >
                    ГГД
                </Typography>
                <FormControlLabel
                    value={outerFilters.isShowLKS}
                    control={
                        <Switch
                            checked={outerFilters.isShowLKS}
                            onChange={() =>
                                setOuterFilters({
                                    ...outerFilters,
                                    isShowLKS: !outerFilters.isShowLKS,
                                })
                            }
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    }
                    label={<Typography variant='subtitle2'>Показывать ЛКС</Typography>}
                    labelPlacement='end'
                />
                {componentConfig?.withIsOnlyDrilling && (
                    <FormControlLabel
                        value={outerFilters.isOnlyDrilling}
                        control={
                            <Switch
                                checked={outerFilters.isOnlyDrilling}
                                onChange={() =>
                                    setOuterFilters({
                                        ...outerFilters,
                                        isOnlyDrilling: !outerFilters.isOnlyDrilling,
                                    })
                                }
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        }
                        label={<Typography variant='subtitle2'>Только бурение</Typography>}
                        labelPlacement='end'
                    />
                )}
                <IconButton onClick={handleResetClick}>
                    <ZoomOutOutlinedIcon />
                </IconButton>
                {componentConfig?.withFullScreen && (
                    <IconButton
                        onClick={() => {
                            setFullScreen(!fullScreen);
                        }}
                    >
                        {fullScreen ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
                    </IconButton>
                )}
            </Stack>
            <Stack
                gap={1}
                height='90%'
            >
                {chartData && (
                    <Chart
                        outerKey={key}
                        {...otherProps}
                        customConfig={customNewConfig}
                        hideTitle
                        chart={chartData}
                        outerFilters={formattedFilters}
                        withFilters={false}
                    />
                )}
            </Stack>
        </Stack>
    );
};
