import {Button, DialogContentText, Popover, Typography} from '@mui/material';
import {DialogActions, DialogContent} from '@mui/material';
import {ReactNode} from 'react';

type DeletePopoverProps = {
    onConfirmation: (event: React.MouseEvent<HTMLButtonElement>) => void;
    title?: string;
    description?: string | ReactNode;
    confirmText?: string;
    anchorEl: any;
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const DeletePopover = ({
    onConfirmation,
    title = 'Вы уверены, что хотите удалить запись?',
    description,
    confirmText = 'Удалить',
    onClose,
    anchorEl,
}: DeletePopoverProps) => {
    const open = Boolean(anchorEl);

    const id = open ? 'popover' : undefined;
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: {
                        backgroundColor: 'var(--mui-palette-paper_elevation-16) !important',
                        backgroundImage: 'none !important',
                        maxWidth: 400,
                    },
                },
            }}
        >
            <Typography
                variant='subtitle2'
                padding={2}
            >
                {title}
            </Typography>
            {description && (
                <DialogContent>
                    <DialogContentText>{description}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button
                    size='small'
                    onClick={onClose}
                >
                    Отмена
                </Button>
                <Button
                    onClick={onConfirmation}
                    variant='contained'
                    color='primary'
                    size='small'
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Popover>
    );
};
