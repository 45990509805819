import {lazy, memo} from 'react';
import {createBrowserRouter, Link, Navigate, RouterProvider} from 'react-router-dom';
import {Container} from 'typedi';

import {WellDesignPath} from '@features/wellDesign/route';
import Layout from './components/Layout';
import {routerToken} from '@core/di/tokens';
import {DashboardContent, DashboardPage, TimeBalanceContent} from '@pages/DashboardPage';
import {MonitoringType} from '@pages/monitoring/types';
import {GuardedRoute} from '@ui/GuardedRoute/GuardedRoute';
import {Routes as RoutesLinks} from './constants/routes';
import {Permission} from './utils/hooks/permissions/usePermissions';
import withSuspense from './utils/hoc/withSuspense';

import 'styles/app.css';
import {ADMIN_ROUTES} from "@pages/AdministrationPage/constants";


const MonitoringPage = lazy(() => import('@pages/monitoring/Dashboard'));
const TrajectoryPage = lazy(() => import('@pages/TrajectoryPage'));
const TrajectoryTablePage = lazy(() => import('@pages/TrajectoryTablePage'));
const SMBOPage = lazy(() => import('@pages/SMBOPage'));
const SMBOViewPage = lazy(() => import('@pages/SMBOViewPage'));
const Page = lazy(() => import('@pages/TablePage'));
const FilesPage = lazy(() => import('@pages/FilesPage/FilesPage'));
const WellsPage = lazy(() => import('@pages/WellsPage/WellsPage'));
const CalendarPage = lazy(() => import('@pages/CalendarPage/CalendarPage'));
const PricesPage = lazy(() => import('@pages/PricesPage/PricesPage'));
const KnbkElementsPage = lazy(() => import('@pages/Knbkpage/KnbkElementsPage'));
const KnbkGroupsPage = lazy(() => import('@pages/Knbkpage/KnbkGroupsPage'));
const WellDesign = lazy(() => import('@features/wellDesign/WellDesign'));
const MainPage = lazy(() => import('@pages/MainPage/MainPage'));
const ProjectsPage = lazy(() => import('@pages/ProjectsPage/ProjectsPage'));
const CcdpGroupsPage = lazy(() => import('@pages/CcdpPage/CcdpGroupsPage'));
const CcdpElementsPage = lazy(() => import('@pages/CcdpPage/CcdpElementsPage'));
const GroupedPages = lazy(() => import('@pages/GroupedPages'));
const RatesAndPricesPage = lazy(() => import('@pages/RatesAndPricesPage/RatesAndPricesPage'));
const CommonInfoPage = lazy(() => import('@pages/CommonInfoPage/CommonInfoPage'));
const GroupedPagesProject = lazy(() => import('@pages/GroupedPages/project/GroupedPagesProject'));
const KnbkGisGroupsPage = lazy(() => import('@pages/KnbkGisPage/KnbkGisGroupsPage'));
const KnbkGisElementsPage = lazy(() => import('@pages/KnbkGisPage/KnbkGisElementsPage'));
const LessonsDatabasePage = lazy(() => import('@pages/LessonsDatabasePage/LessonsDatabasePage'));
const MonitoringWells = lazy(() => import('@pages/MonitoringWells/MonitoringWells'));
const GgdPage = lazy(() => import('@pages/GgdPage/GgdPage'));
const StageAnalyticsPage = lazy(() => import('@pages/StageAnalyticsPage/StageAnalyticsPage'));
const AutoDrillingPage = lazy(() => import('@pages/AutoDrillingPage/AutoDrillingPage'));
const GroupedPagesAutoDrilling = lazy(
    () => import('@pages/GroupedPages/autoDrilling/GroupedPagesAutoDrilling'),
);
const CrewEfficiency = lazy(() => import('@features/crewEfficiency/CrewEfficiency'));
const MonitoringLayout = lazy(() => import('@pages/monitoring/components/Layout/MonitoringLayout'));
const GroupedPagesDrillingCarpet = lazy(
    () => import('@pages/GroupedPages/drillingCarpet/GroupedPagesDrillingCarpet'),
);
const DrillingCarpetPage = lazy(() => import('@pages/DrillingCarpetPage/AutoDrillingPage'));
const EquipmentViewPage = lazy(() => import('@pages/EquipmentViewPage/EquipmentViewPage'));
const MomentAndWeightLoader = lazy(() => import('@features/momentAndWeight/MomentAndWeightLoader'));
const KnbkProjectGroupsPage = lazy(() => import('@pages/KnbkProjectPage/KnbkProjectGroupsPage'));
const KnbkProjectElementsPage = lazy(
    () => import('@pages/KnbkProjectPage/KnbkProjectElementsPage'),
);
const AdministrationPage = lazy(() => import('@pages/AdministrationPage/AdministrationPage'));

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to='/'>Go to the home page</Link>
            </p>
        </div>
    );
}

export const router = createBrowserRouter([
    {
        path: '',
        element: <Layout hasSidebar={true} />,
        children: [
            {
                path: '',
                element: withSuspense(GroupedPages),
                children: [
                    {
                        path: `${RoutesLinks.Files}/:wellId/:pageId/:id`,
                        element: withSuspense(FilesPage),
                    },
                    {
                        path: `${RoutesLinks.Tables}/:wellId?/:pageId?/:data?`,
                        element: withSuspense(Page),
                    },
                    {
                        path: `${RoutesLinks.Knbk}/:wellId/:pageId/`,
                        element: withSuspense(KnbkGroupsPage),
                    },
                    {
                        path: `${RoutesLinks.Knbk}/:wellId/:pageId/:pageType/:groupId`,
                        element: withSuspense(KnbkElementsPage),
                    },
                    {
                        path: `${RoutesLinks.KnbkGis}/:wellId/:pageId/`,
                        element: withSuspense(KnbkGisGroupsPage),
                    },
                    {
                        path: `${RoutesLinks.KnbkGis}/:wellId/:pageId/:groupId`,
                        element: withSuspense(KnbkGisElementsPage),
                    },
                    {
                        path: `${RoutesLinks.Ccdp}/:wellId/:pageId`,
                        element: withSuspense(CcdpGroupsPage),
                    },
                    {
                        path: `${RoutesLinks.Ccdp}/:wellId/:pageId/:groupId`,
                        element: withSuspense(CcdpElementsPage),
                    },
                    {
                        path: `${RoutesLinks.Calendar}/:wellId/:pageId`,
                        element: withSuspense(CalendarPage),
                    },
                    {
                        path: `${RoutesLinks.Ggd}/:wellId/:pageId`,
                        element: withSuspense(GgdPage),
                    },
                    {
                        path: `${RoutesLinks.Dashboard}/:wellId?`,
                        element: <DashboardPage />,
                        children: [
                            {
                                index: true,
                                element: <DashboardContent />,
                            },
                            {
                                path: 'time-balance',
                                element: <TimeBalanceContent />,
                            },
                            {
                                path: WellDesignPath,
                                element: withSuspense(WellDesign),
                            },
                        ],
                    },
                    {
                        path: `${RoutesLinks.Trajectory}/:wellId`,
                        element: withSuspense(TrajectoryPage),
                    },
                    {
                        path: `${RoutesLinks.Trajectory}/:wellId/:pageId/:groupId?`,
                        element: withSuspense(TrajectoryTablePage),
                    },
                ],
            },
        ],
    },
    {
        path: '',
        children: [
            {
                path: '',
                element: <GuardedRoute permission={Permission.CAN_VIEW_MONITORING}></GuardedRoute>,
                children: [
                    {
                        path: '',
                        element: <Layout hasSidebar={false} />,
                        children: [
                            {
                                path: `${RoutesLinks.Monitoring}/`,
                                element: withSuspense(MonitoringWells),
                            },
                        ],
                    },
                    {
                        path: '',
                        element: withSuspense(MonitoringLayout),
                        children: [
                            {
                                path: '',
                                element: (
                                    <GuardedRoute
                                        permission={Permission.CAN_VIEW_DRILLING_EFFICIENCY}
                                    ></GuardedRoute>
                                ),
                                children: [
                                    {
                                        path: `${RoutesLinks.Monitoring}/efficiency/:wellId`,
                                        element: withSuspense(CrewEfficiency),
                                    },
                                ],
                            },
                            {
                                path: `${RoutesLinks.Monitoring}/moment-weight/:wellId`,
                                element: withSuspense(MomentAndWeightLoader),
                            },
                            {
                                path: `${RoutesLinks.Monitoring}/las/:wellId`,
                                element: withSuspense(MonitoringPage, {type: MonitoringType.las}),
                            },
                            {
                                path: `${RoutesLinks.Monitoring}/las/:wellId/:lasId?`,
                                element: withSuspense(MonitoringPage, {type: MonitoringType.las}),
                            },
                            {
                                id: 'rt-monitoring-page',
                                path: `${RoutesLinks.Monitoring}/:wellId`,
                                element: withSuspense(MonitoringPage, {
                                    type: MonitoringType.realTime,
                                }),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '',
        element: <Layout hasSidebar={false} />,
        children: [
            {
                path: '',
                element: <GuardedRoute permission={Permission.Projects}></GuardedRoute>,
                children: [
                    {
                        path: '',
                        element: withSuspense(GroupedPagesProject),
                        children: [
                            {
                                path: `${RoutesLinks.Projects}${RoutesLinks.Tables}/:projectId/:pageId?/:data?`,
                                element: withSuspense(Page),
                            },
                            {
                                path: `${RoutesLinks.KnbkProject}/:projectId/:pageId/`,
                                element: withSuspense(KnbkProjectGroupsPage),
                            },
                            {
                                path: `${RoutesLinks.KnbkProject}/:projectId/:pageId/:groupId`,
                                element: withSuspense(KnbkProjectElementsPage),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '',
        element: <Layout hasSidebar={false} />,
        children: [
            {
                index: true,
                path: RoutesLinks.Main,
                element: withSuspense(MainPage),
            },
            {
                path: '',
                element: (
                    <GuardedRoute
                        permission={[Permission.Wells, Permission.CAN_VIEW_WELL_HEADER]}
                    ></GuardedRoute>
                ),
                children: [
                    {
                        path: RoutesLinks.Wells,
                        element: withSuspense(WellsPage),
                    },
                ],
            },
            {
                path: '',
                element: <GuardedRoute permission={Permission.Projects}></GuardedRoute>,
                children: [
                    {
                        path: RoutesLinks.Projects,
                        element: withSuspense(ProjectsPage),
                    },
                ],
            },
            {
                path: '',
                element: <GuardedRoute permission={Permission.Prices}></GuardedRoute>,
                children: [
                    {
                        path: RoutesLinks.RatesAndPrices,
                        element: withSuspense(RatesAndPricesPage),
                    },
                ],
            },
            {
                path: '',
                element: <GuardedRoute permission={Permission.Prices}></GuardedRoute>,
                children: [
                    {
                        path: `${RoutesLinks.Prices}/:contractId`,
                        element: withSuspense(PricesPage),
                    },
                ],
            },
            {
                path: '',
                element: <GuardedRoute permission={Permission.CommonInfo}></GuardedRoute>,
                children: [
                    {
                        path: `${RoutesLinks.CommonInfo}/:wellId?`,
                        element: withSuspense(CommonInfoPage),
                    },
                    {
                        path: `${RoutesLinks.StageAnalytics}/:wellId?`,
                        element: withSuspense(StageAnalyticsPage),
                    },
                ],
            },
            {
                path: '',
                element: (
                    <GuardedRoute permission={Permission.CanViewIncidentAnalyse}></GuardedRoute>
                ),
                children: [
                    {
                        path: RoutesLinks.LessonsDatabase,
                        element: withSuspense(LessonsDatabasePage),
                    },
                ],
            },

            {
                path: '',
                element: <GuardedRoute permission={Permission.CanViewAutoDrilling}></GuardedRoute>,
                children: [
                    {
                        path: '',
                        element: withSuspense(GroupedPagesAutoDrilling),
                        children: [
                            {
                                path: `${RoutesLinks.AutoDrilling}/:pageId`,
                                element: withSuspense(AutoDrillingPage),
                            },
                        ],
                    },
                ],
            },
            {
                path: '',
                element: (
                    <GuardedRoute permission={Permission.CAN_VIEW_DRILLING_CARPET}></GuardedRoute>
                ),
                children: [
                    {
                        path: '',
                        element: withSuspense(GroupedPagesDrillingCarpet),
                        children: [
                            {
                                path: `${RoutesLinks.DrillingCarpet}/:pageId`,
                                element: withSuspense(DrillingCarpetPage),
                            },
                        ],
                    },
                ],
            },
            {
                path: '',
                element: (
                    <GuardedRoute permission={Permission.CAN_VIEW_DRILLING_RIG}></GuardedRoute>
                ),
                children: [
                    {
                        path: RoutesLinks.SMBO,
                        element: withSuspense(SMBOPage),
                    },
                    {
                        path: `${RoutesLinks.Wells}/:wellId/smbo`,
                        element: withSuspense(SMBOViewPage),
                    },
                    {
                        path: `${RoutesLinks.Wells}/:wellId/equipment/:equipmentId`,
                        element: withSuspense(EquipmentViewPage),
                    },
                ],
            },
            {
                path: '',
                element: <GuardedRoute permission={Permission.CAN_VIEW_ADMIN_PANEL}></GuardedRoute>,
                children: [
                    {
                        path: RoutesLinks.Administration,
                        element: withSuspense(AdministrationPage),
                        children: [
                            {path: '', element: <Navigate to={ADMIN_ROUTES[0].path}/>},
                            ...ADMIN_ROUTES,
                        ],
                    }
                ],
            },
        ],
    },
    {
        path: '*',
        element: <NoMatch />,
    },
]);

Container.set(routerToken, router);

const App = memo(() => {
    return <RouterProvider router={router} />;
});

export default App;
