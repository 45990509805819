import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {wordsDeclension} from './typography';

dayjs.extend(utc);

export type RawDate = number | string | Date;

const FULL_DATE = 'DD.MM.YYYY';
const FULL_TIME = 'HH:mm:ss';
const SHORT_TIME = 'HH:mm';
const SHORT_DATE = 'DD.MM.YY';
const UTC_DATE = 'YYYY-MM-DD';
const MONTH_YEAR = 'MMMM YYYY';

export const DT_FORMATS = {
    default: `${FULL_DATE} ${SHORT_TIME}`,
    defaultDate: FULL_DATE,
    defaultTime: FULL_TIME,
    full: `${FULL_DATE} ${FULL_TIME}`,
    shortDate: `${SHORT_DATE}`,
    short: `${SHORT_DATE} ${SHORT_TIME}`,
    fullDate: FULL_DATE,
    fullTime: FULL_TIME,
    shortTime: SHORT_TIME,
    utcDate: UTC_DATE,
    utcShortTime: `${UTC_DATE}T${SHORT_TIME}`,
    utcFull: `${UTC_DATE}T${FULL_TIME}`,
    utcWithTimezone: `${UTC_DATE}T${FULL_TIME}Z`,
    monthYearFull: MONTH_YEAR,
} as const;

export enum timeInS {
    millisecond = 0.001,
    second = 1,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7,
}

/**
 * Проверка значения на возможность преобразования к дате
 *
 * @param value Проверяемое значение
 * @returns Является ли значение потенциальной датой
 */
export function isRawDate(value: unknown): value is RawDate {
    return !isNaN(Date.parse(String(value)));
}

/**
 * Форматировать значение как дату в строку
 *
 * @param date Форматируемое значение
 * @param utc Преобразовывать ли к UTC
 * @param format Формат вывода
 * @returns Форматированная дата в строке
 */
export function formatDate(date: unknown, format = 'default', utc = false): string {
    if (!isRawDate(date)) return '';

    const dtFormat = format in DT_FORMATS ? DT_FORMATS[format as keyof typeof DT_FORMATS] : format;
    const out = utc ? dayjs(date).utc().local() : dayjs(date);

    return out.format(dtFormat);
}

export function getInterval(date1: unknown, date2: unknown) {
    if (!isRawDate(date1) || !isRawDate(date2)) return null;

    let sDiff = dayjs(date1).diff(dayjs(date2), 's');

    if (sDiff < 0) {
        sDiff = Math.abs(sDiff);
    }
    return getFormattedDuration(sDiff);
}

const ONE_MINUTE = 60;
const ONE_HOUR = 60 * 60;
const ONE_DAY = 60 * 60 * 24;
const ONE_MONTH = 60 * 60 * 24 * 31;
const ONE_YEAR = 60 * 60 * 24 * 31 * 12;

export function getFormattedDuration(durationInSeconds: number) {
    if (durationInSeconds < ONE_MINUTE) {
        return `${durationInSeconds}\u00A0${wordsDeclension(durationInSeconds, [
            'секунда',
            'секунды',
            'секунд',
        ])}`;
    }

    if (durationInSeconds < ONE_HOUR) {
        const diff = Math.round(durationInSeconds / ONE_MINUTE);
        return `${diff}\u00A0${wordsDeclension(diff, ['минута', 'минуты', 'минут'])}`;
    }

    if (durationInSeconds < ONE_DAY) {
        const diff = Math.round(durationInSeconds / ONE_HOUR);
        return `${diff}\u00A0${wordsDeclension(diff, ['час', 'часа', 'часов'])}`;
    }

    if (durationInSeconds < ONE_MONTH) {
        const diff = Math.round(durationInSeconds / ONE_DAY);
        return `${diff}\u00A0${wordsDeclension(diff, ['день', 'дня', 'дней'])}`;
    }

    if (durationInSeconds < ONE_YEAR) {
        const diff = Math.round(durationInSeconds / ONE_MONTH);
        return `${diff}\u00A0${wordsDeclension(diff, ['месяц', 'месяца', 'месяцев'])}`;
    }

    const diff = Math.round(durationInSeconds / ONE_YEAR);
    return `${diff}\u00A0${wordsDeclension(diff, ['год', 'года', 'лет'])}`;
}

export const getDateArrayInInterval = function (startDate: string, endDate: string) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
  
    // Проверка на корректность дат
    if (end.isBefore(start)) {
      throw new Error('Дата окончания не может быть раньше даты начала');
    }
  
    // Массив для хранения всех дат
    const dates = [];
    let current = start;
  
    // Цикл для добавления всех дат в массив
    while (current.isBefore(endDate) || current.isSame(endDate, 'day')) {
      dates.push(current.format(DT_FORMATS.utcDate));
      current = current.add(1, 'day');
    }
  
    return dates;
};
