import {SxProps} from '@mui/material';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

import {Routes} from '../../constants/routes';
import {AdministrationPageItem} from '@pages/AdministrationPage/types';
import Accounts from '@pages/AdministrationPage/pages/Accounts/Accounts';
import {makeAdminRoutes, makeSideMenuItem} from '@pages/AdministrationPage/utils';

export const STYLES: Record<string, SxProps> = {
    wrapper: {
        position: 'fixed',
        top: '70px',
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    menu: {
        width: '64px',
        height: '100vh',
        backgroundColor: 'transparent',
        borderRight: '1px solid var(--mui-palette-divider)',
        padding: '32px 12px',
        paddingLeft: '11px',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 16px',
        width: '100%'
    },
};

export const ADMINISTRATOR_PAGES: AdministrationPageItem[] = [
    {
        route: Routes.Accounts,
        label: 'Учетные записи',
        icon: <AccountBoxOutlinedIcon />,
        element: <Accounts />,
    },
];

export const MENU_ITEMS = ADMINISTRATOR_PAGES.map(({label, route, icon}: AdministrationPageItem) =>
    makeSideMenuItem(label, route, icon),
);

export const ADMIN_ROUTES = ADMINISTRATOR_PAGES.map(({route, element}) =>
    makeAdminRoutes(route, element),
);

export const FILTERS = [
    {
        label: 'Категория доступа',
        key: 'accessCategory',
    },
    {
        label: 'Организация',
        key: 'contractor',
    },
];
