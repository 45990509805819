import {Chip, IconButton, Stack, Typography} from '@mui/material';
import {useQuery} from '@tanstack/react-query';
import {useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {RouteParams} from 'utils';
import {CloseOutlined} from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {WellsService} from '@services/Wells/WellsService';
import {useTripDetailsData} from './hooks/useTripDetailsData';

const service = WellsService.getInstance();
type Props = {
    tripId: number;
    onClose: () => void;
    setSelectedTripByIndex?: (idx: number | null) => void;
    currentTripIndex?: number;
    tripsCount?: number;
};
export const TripHeader = ({
    tripId,
    onClose,
    setSelectedTripByIndex,
    currentTripIndex,
    tripsCount,
}: Props) => {
    const {data: tripDetails} = useTripDetailsData(tripId);
    const params = useParams<RouteParams>();

    const loadWellById = useCallback(() => {
        return service.getWellById(params.wellId || tripDetails?.wellId);
    }, [params.wellId, tripDetails?.wellId]);

    const {data: well} = useQuery({
        queryKey: [`/well/${params.wellId || tripDetails?.wellId}`],
        queryFn: loadWellById,
        enabled: params.wellId !== undefined || tripDetails?.wellId !== undefined,
    });

    return (
        <Stack
            direction='row'
            className='dnd_handle'
            sx={{cursor: 'move'}}
            alignItems='center'
            justifyContent='space-between'
        >
            <Stack
                direction='row'
                gap='16px'
                alignItems='center'
            >
                <Typography variant='h3'>
                    <div
                        style={{
                            background: 'var(--mui-palette-action-hover)',
                            borderRadius: '4px',
                            padding: '4px',
                        }}
                    >
                        {well?.name}
                    </div>
                </Typography>

                <Stack
                    direction='row'
                    gap='8px'
                    alignItems='center'
                >
                    {setSelectedTripByIndex && currentTripIndex !== undefined && (
                        <IconButton
                            onClick={() => setSelectedTripByIndex(currentTripIndex - 1)}
                            disabled={currentTripIndex === 0}
                        >
                            <ChevronRightIcon sx={{transform: 'rotate(180deg)'}} />
                        </IconButton>
                    )}
                    <Typography variant='h6'>{tripDetails?.title}</Typography>
                    {setSelectedTripByIndex && currentTripIndex !== undefined && (
                        <IconButton
                            onClick={() => setSelectedTripByIndex(currentTripIndex + 1)}
                            disabled={currentTripIndex === tripsCount}
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    )}
                </Stack>

                <Chip
                    color='success'
                    variant='outlined'
                    label={tripDetails?.casingColumn}
                    sx={{
                        height: '32px',
                        borderRadius: '16px',
                        color: 'var(--mui-palette-success-main)',
                    }}
                />
            </Stack>
            <IconButton onClick={onClose}>
                <CloseOutlined />
            </IconButton>
        </Stack>
    );
};
