export type RtmTooltip = {
    id: string;
    type: RtmGraphTooltipMode;
    timestamp: number;
    trackId: string;
    offset?: {
        x: number;
        y: number;
    };
    // для point тултипов сохраняем что именно выбрано было
    datasetLabel?: string;
    // для interval тултипов сохраняем начало и конец интервала
    timestampEnd?: number;
};

export enum RtmGraphTooltipMode {
    line = 'line',
    point = 'point',
    interval = 'interval',
}

export enum RtmGraphIndexType {
    time = '1001',
    depth = '1002',
}

export enum MonitoringType {
    realTime = 'realTime',
    las = 'las',
}
