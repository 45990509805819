import {useCallback, useMemo, useState} from 'react';
import {Box, Button, Stack} from '@mui/material';
import Add from '@mui/icons-material/Add';

import Filters from '@pages/AdministrationPage/components/Filters';
import {FILTERS} from '@pages/AdministrationPage/constants';
import {useDict} from '../../../../utils/hooks/useDict';

const Accounts = () => {
    const [filtersValue, setFiltersValue] = useState<Record<string, string | null>>();
    const {data: contractorList = []} = useDict({code: 'Contractor'});

    const handleChangeFilters = useCallback((value: Record<string, string | null>) => {
        setFiltersValue((prev) => ({...(prev || {}), ...value}));
    }, []);

    const options = useMemo(() => ({
        contractor: contractorList.map(({id, value}) => ({value: id, label: value.trim()})),
    }), [contractorList]);

    return (
        <Box component='div'>
            <Stack
                direction='row'
                gap={2}
                alignItems='flex-end'
            >
                <Filters
                    filters={FILTERS}
                    setFilters={handleChangeFilters}
                    options={options}
                    outerValue={filtersValue}
                />
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<Add />}
                    size='medium'
                >
                    Новый пользователь
                </Button>
            </Stack>
        </Box>
    );
};

export default Accounts;
