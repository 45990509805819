export enum ReportType {
    'VMR' = 'VMR',
    'DRILLING' = 'DRILLING',
    'WELL_COMPLETION' = 'WELL_COMPLETION',
    'WELL_CONSTRUCTION' = 'WELL_CONSTRUCTION',
    'TRAJECTORY' = 'TRAJECTORY',
    'DRILLING_LIQUORS' = 'DRILLING_LIQUORS',
    'CHISEL' = 'CHISEL',
    'BHA_RUN' = 'BHA_RUN',
    'MTR' = 'MTR',
    'CHEMICAL_REAGENT_CONSUMPTION' = 'CHEMICAL_REAGENT_CONSUMPTION',
    'TECHNIQUE' = 'TECHNIQUE',
    'NPV' = 'NPV',
    'OT_PB' = 'OT_PB',
    'BKV' = 'BKV',
}

export const reportsOptionsTitleMap: {[key in ReportType]: string} = {
    [ReportType.BKV]: 'БКВ',
    [ReportType.DRILLING]: 'Бурение',
    [ReportType.DRILLING_LIQUORS]: 'Буровые растворы',
    [ReportType.CHEMICAL_REAGENT_CONSUMPTION]: 'Хим. реагенты',
    [ReportType.WELL_CONSTRUCTION]: 'Конструкция',
    [ReportType.CHISEL]: 'Долота',
    [ReportType.MTR]: 'МТР',
    [ReportType.NPV]: 'НПВ',
    [ReportType.WELL_COMPLETION]: 'Освоение',
    [ReportType.OT_PB]: 'ОТ и ПБ',
    [ReportType.BHA_RUN]: 'Рейсы',
    [ReportType.TECHNIQUE]: 'Техника',
    [ReportType.TRAJECTORY]: 'Траектория',
    [ReportType.VMR]: 'ВМР',
};
