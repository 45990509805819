import {useMemo, useState} from 'react';
import {useMenuItems} from './useMenuItems';
import {MenuItem, MenuItemType, RouteParams} from '../../../types/common';
import {Button, SvgIcon, Typography} from '@mui/material';
import classNames from 'classnames';

import styles from './Menu.module.scss';
import {Link, useLocation, useParams} from 'react-router-dom';
import {getChildUrlsForMenuItem, getUrlForMenuItem} from './utils';
import {NavLink} from 'react-router-dom';
import {iconByMenuItemCode} from '../../../slices/menu/consts';
import {firtsElorValue} from 'utils';
const MenuItemLink = ({
    item,
    wellId,
    onItemClick,
}: {
    item: MenuItem;
    wellId: string;
    onItemClick: () => void;
}) => {
    const {pathname} = useLocation();
    const url = getUrlForMenuItem(item, wellId);
    const childUrls = getChildUrlsForMenuItem(item, wellId);

    const icon = useMemo(() => (item.code ? iconByMenuItemCode[item.code] : null), [item.code]);
    const isActive =
        item.type === MenuItemType.Trajectory
            ? childUrls?.some((i) => pathname.includes(i as string))
            : childUrls?.includes(pathname);

    return (
        <div
            className={styles.menuItem}
            onClick={onItemClick}
        >
            <Button
                component={NavLink}
                to={firtsElorValue(url)}
                color='inherit'
                size='small'
                className={classNames({
                    [styles.menuActive]: isActive,
                })}
                sx={{
                    color: 'var(--mui-palette-action-active)',
                    width: 'min-content',
                }}
            >
                {icon ? (
                    <SvgIcon
                        component={icon}
                        inheritViewBox
                        sx={{
                            height: '20px',
                            width: '20px',
                        }}
                    />
                ) : (
                    item.label
                )}
            </Button>

            <Typography
                variant='body2'
                className={styles.tooltip}
                component={Link}
                to={firtsElorValue(url)}
                role='tooltip'
            >
                {item.label}
            </Typography>
        </div>
    );
};

const Menu = () => {
    const menuItems = useMenuItems();
    const {wellId} = useParams<RouteParams>();

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <section
            className={classNames(styles.wrapper, {
                [styles.hovered]: isHovered,
            })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            id='menu-section'
        >
            {menuItems.map((menuItem) => (
                <MenuItemLink
                    key={menuItem.code || firtsElorValue(menuItem.key)}
                    item={menuItem}
                    wellId={wellId ?? ''}
                    onItemClick={handleMouseLeave}
                />
            ))}
        </section>
    );
};

export default Menu;
