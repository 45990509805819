import {Stack} from '@mui/material';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import {ListPopupNode, ListPopupSummary} from '@features/incident/types';
import {colors} from '../../constants';
import {CommonAccordionSummary} from './CommonAccordionSummary';
import {ListPopupSummaryRow} from './ListPopupSummaryRow';
import {accordionStyles} from '../utils';

type ListPopupSummaryAccoridonProps = {
    summary: ListPopupSummary;
    idx: number;
    selectedNode: ListPopupNode | null;
    setSelectedNode: (node: ListPopupNode) => void;
};
export const ListPopupSummaryAccoridon: React.FC<ListPopupSummaryAccoridonProps> = ({
    summary,
    idx,
    selectedNode,
    setSelectedNode,
}) => (
    <Accordion sx={accordionStyles}>
        <CommonAccordionSummary
            idx={idx}
            summary={summary}
        />
        <AccordionDetails sx={{borderLeft: `5px solid ${colors[idx]}`, padding: '0'}}>
            <Stack direction='column'>
                {summary.incidents.map((incident) => (
                    <ListPopupSummaryRow
                        key={incident.id}
                        node={incident}
                        selectedNode={selectedNode}
                        setSelectedNode={setSelectedNode}
                        summaryTitle={summary.title}
                    />
                ))}
            </Stack>
        </AccordionDetails>
    </Accordion>
);
