import api from '../../utils/api';
import {DashboardProps, FieldsCommonInfo, PadItem} from '../../pages/DashboardPage/types';
import {TimeBalanceAnalyticResponse, TimeBalanceTableResponse} from '../../pages/DashboardPage';
import {ChartFilter, ChartProps} from 'components/Chart/types';
import {CostItem, PricesByPlan} from 'features/Cost/types';
import {BuildingWellData} from '@pages/CommonInfoPage/components/WellCommonInfo/types';
import {DictionaryItem, ParamsType} from 'utils';

export class DashboardService {
    private static instance: DashboardService;

    public static getInstance() {
        if (!DashboardService.instance) {
            DashboardService.instance = new DashboardService();
        }

        return DashboardService.instance;
    }

    private constructor() {}

    public getChartsList(wellId: number) {
        return api.get<DashboardProps>('/table/dashboard/chart', {wellId});
    }

    public getChartsByWellCommonInfo(wellId: number) {
        return api.get<DashboardProps>('/table/dashboard/common-info/chart/info', {wellId});
    }

    public getChartsForCommonInfoPage() {
        return api.get<DashboardProps>('/table/dashboard/field-common-info/chart/info');
    }

    public getChart(chartCode: string, filterParams: ChartFilter) {
        return api.post<ChartProps>(`/table/chart/${chartCode}`, filterParams, {
            withoutGlobalLoader: true,
        });
    }

    public getTimeBalanceTable(wellId: number, page: number, size = 10, sort?: string) {
        return api.get<TimeBalanceTableResponse>('/table/dashboard/balance/time', {
            wellId,
            size,
            sort,
            page: page - 1,
        });
    }

    public getTimeBalanceData(wellId: number, date: string) {
        return api.get<TimeBalanceAnalyticResponse>(
            '/table/dashboard/balance/time/detailed',
            {
                wellId,
                date,
            },
            {withoutGlobalLoader: true},
        );
    }

    public getDashboardCosts(wellId: string, ggdId: string | null) {
        return api.get<CostItem>(`/table/dashboard/prices`, {wellId, ggdPlanId: ggdId});
    }

    public getDashboardCostsByContracts(wellId: string, ggdId: string, contracts: number[]) {
        return api.get<PricesByPlan>('/table/dashboard/prices-by-plan', {
            wellId,
            contractId: contracts,
            ggdPlanId: ggdId,
        });
    }

    public getPricesChartList(wellId: number) {
        return api.get<DashboardProps>('/table/dashboard/prices/chart/info', {wellId});
    }

    public getFieldsCommonInfo(): Promise<FieldsCommonInfo> {
        return api.get<FieldsCommonInfo>('/table/dashboard/field-common-info');
    }

    public getWellsInfoByField(params: ParamsType): Promise<PadItem[]> {
        return api.get('/table/dashboard/field-common-info/wellsInfoByField', params);
    }

    public getBuildingDatesInfo(wellId: string): Promise<BuildingWellData[]> {
        return api.get<BuildingWellData[]>(
            '/table/dashboard/well-common-info/building-dates-info',
            {wellId},
        );
    }

    public getStageAnalyseChartList(wellId: number) {
        return api.get<DashboardProps>('/dashboard/stage-analyse/chart/info', {wellId});
    }

    public getTrajectoryChartList(wellId: number) {
        return api.get<DashboardProps>('/table/dashboard/trajectory/chart/info', {wellId});
    }

    public getKnbkChartList(wellId: number) {
        return api.get<DashboardProps>('/table/dashboard/knbk/chart/info', {wellId});
    }

    public getWellPadsByFieldId(fieldId: string) {
        return api.get<DictionaryItem[]>('/well-pads/find-by-field', {fieldId});
    }
}
