import {
    BarChartProps,
    ChartCode,
    ChartItem,
    ChartProps,
    ChartType,
    ColumnChartProps,
    LineChartItem,
    LineChartProps,
    PieChartItem,
    RangeBarItem,
    RangeBarProps,
    ReflectConfig,
} from './types';
import {
    Bar,
    BarConfig,
    Column,
    ColumnConfig,
    getCanvasPattern,
    Line,
    LineConfig,
    Pie,
    PieConfig,
} from '@ant-design/plots';
import {Annotation, G2, LineOptions as G2plotConfig} from '@antv/g2plot';
import {DT_FORMATS, formatDate} from '../../utils';
import {deepMix} from '@antv/util';
import {SupportedColorScheme} from '@mui/material';
import {colors} from '@features/incident/constants';
import {getBarHorizontalTooltip} from './BarHorizontalTooltip';

export const chartHasData = (chart: ChartItem, values: ChartProps): boolean => {
    if (chart.type === ChartType.Line) {
        return (values as LineChartProps).some((line) => Boolean(line.values.length));
    }

    return Boolean(values.length);
};

export const checkShowEmptyValue = (chart: ChartItem, chartHasData: boolean) => {
    if (!chartHasData && chart.code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS) {
        return 'Выберите Хим.реагенты и Обсадную колонну для отображения диаграммы';
    }
    if (!chartHasData && chart.code === ChartCode.MTR_CONSUMPTIONS) {
        return 'Выберите Наименование МТР и Обсадную колонну для отображения диаграммы';
    }
    return 'Нет данных';
};

export const splitStringToLines = (str: string, maxLength = 18) => {
    const parts = str.split(' ');
    const res = [];
    let accum = parts[0];
    for (let i = 1; i < parts.length; i++) {
        const word = parts[i];
        if (accum.length + word.length + 1 <= maxLength) {
            accum += ' ' + word;
        } else {
            res.push(accum);
            accum = word;
        }
    }
    if (accum) {
        res.push(accum);
    }
    return res.join(' \n');
};

export const getChartByType = (type: ChartType) => {
    switch (type) {
        case ChartType.Bar:
        case ChartType.RangeBar:
            return Bar;
        case ChartType.Column:
            return Column;
        case ChartType.Pie:
            return Pie;
        case ChartType.Line:
        default:
            return Line;
    }
};

export const getChartsConfig = (
    chart: ChartItem,
    values: ChartProps,
    colorScheme: SupportedColorScheme | undefined,
    customConfig?: (
        config: Omit<G2plotConfig, 'tooltip' | 'data'>,
        values?: ChartProps,
    ) => G2plotConfig,
    isFullscreen?: boolean,
) => {
    const theme = G2.getTheme(colorScheme);
    const defaultConfig = {
        tooltip: {
            domStyles: {
                ...theme.components.tooltip.domStyles,
                'g2-tooltip-marker': {
                    width: '20px',
                    height: '20px',
                },
                'g2-tooltip-list-item': {
                    display: 'flex',
                    alignItems: 'center',
                },
                'g2-tooltip-title': {
                    fontSize: '14px',
                },
            },
        },
    };

    let config;

    switch (chart.type) {
        case ChartType.Bar:
            config = getBarConfig(chart, colorScheme, values as BarChartProps);
            break;
        case ChartType.RangeBar:
            config = getRangeBarConfig(values as RangeBarProps, colorScheme);
            break;
        case ChartType.Column:
            config = getColumnConfig(chart, colorScheme, values as ColumnChartProps);
            break;
        case ChartType.Pie:
            config = getPieConfig(chart, colorScheme, isFullscreen);
            break;
        case ChartType.Line:
        default:
            config = getLineConfig(chart, colorScheme);
            break;
    }
    config = combiningConfigs(defaultConfig, config);

    return customConfig ? customConfig(config, values) : config;
};

export const getRangeBarConfig = (
    props: RangeBarProps,
    colorScheme: SupportedColorScheme | undefined,
): Omit<BarConfig, 'data'> => {
    const [min, max] = getMainRange(props);

    return {
        xField: 'x',
        yField: 'groupName',
        seriesField: 'seriesName',
        isRange: true,
        maxBarWidth: 20,
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        meta: {
            x: {
                type: 'time',
                min,
                max,
            },
        },
        label: {
            content: (data: unknown) => {
                return formatDate((data as RangeBarItem).x[0], DT_FORMATS.defaultDate) as string;
            },
            offsetY: -20,
            offsetX: -10,
            position: () => 'left',
            style: {
                fill: computeFill(colorScheme),
            },
        },
        tooltip: {
            formatter: (data: any) => {
                return {
                    name: data.seriesName as string,
                    value: `${formatDate(data.x[0], DT_FORMATS.defaultDate)} - ${formatDate(
                        data.x[1],
                        DT_FORMATS.defaultDate,
                    )}`,
                };
            },
            fields: ['seriesName', 'x', 'y', 'groupName'],
        },
        xAxis: {
            label: {
                formatter: (text) => {
                    return formatDate(text, DT_FORMATS.defaultDate) as string;
                },
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
    };
};

const getMainRange = (data: RangeBarProps): [string, string] => {
    if (!data?.length) {
        return ['2020-01-01', new Date().toISOString()];
    }

    const firstItem = data[0] as RangeBarItem;
    const mainRange = firstItem.x.map((item) => new Date(item));

    data.forEach((item) => {
        const startDate = new Date(item.x[0]);
        const endDate = new Date(item.x[1]);

        if (startDate < mainRange[0]) {
            mainRange[0] = startDate;
        }

        if (endDate > mainRange[1]) {
            mainRange[1] = endDate;
        }
    });

    return mainRange.map((item) => formatDate(item, DT_FORMATS.utcDate) as string) as [
        string,
        string,
    ];
};

const PATTERN_MAP = {
    Проект: {
        type: 'line',
        cfg: {
            padding: 4,
        },
    },
    План: {
        type: 'square',
        cfg: {
            size: 5,
            padding: 2,
            rotation: 45,
            isStagger: false,
        },
    },
};

type patternProps = {
    groupField?: 'План' | 'Проект';
};

export const pattern = (item: patternProps, color: string) => {
    const patternOptions = deepMix({}, PATTERN_MAP[item.groupField ?? 'План'], {
        cfg: {
            backgroundColor: color,
        },
    });
    return getCanvasPattern(patternOptions) ?? patternOptions;
};

type Accumulator = {
    [key: string]: number;
};

const getAnnotations = (
    data: BarChartProps,
    code: ChartCode,
    colorScheme?: SupportedColorScheme,
) => {
    if (code === ChartCode.BUILDING_DATES_BY_STAGE) {
        const array = Object.entries(
            data.reduce((acc: Accumulator, cur) => {
                //groupField - план/факт/проект
                //x - обсадная колонна
                const x = splitStringToLines(cur.x);
                const key = `${x}-${cur.groupField}`;
                if (!acc[key]) acc[key] = 0;
                acc[key] += cur.y;
                return acc;
            }, {} as Accumulator),
        );
        const res = array.map(([key, total], index) => {
            const [, groupField] = key.split('-');
            return {
                type: 'text',
                content: `${groupField}: ${total.toFixed(2)}`,
                position: (_xScales, yScales) => {
                    const rowsPerSection = 3; // Каждая секция состоит из 3 строк: План, Факт и Проект
                    const totalHeight = 100; // 100% высота графика
                    const numberOfSections = array.length / rowsPerSection; // количество секций
                    const totalRows = numberOfSections * rowsPerSection; // всего строк
                    const totalGaps = numberOfSections * 2; // всего промежутков;

                    // высота промежутка относительно строки
                    const gapHeight = 0.8;
                    // высота строки
                    const elementHeight = totalHeight / (totalRows + totalGaps * gapHeight);
                    // высота секции
                    const sectionHeight =
                        elementHeight * rowsPerSection + elementHeight * gapHeight * 2;

                    function calculateYPosition(sectionIndex: number, groupField: string) {
                        // Промежутки перед текущей секцией
                        const gapCountBeforeSection = sectionIndex * sectionHeight;
                        // // Смещение внутри элемента на основе типа
                        let subelementOffset = 0;
                        switch (groupField) {
                            case 'Факт':
                                subelementOffset = 1;
                                break;
                            case 'План':
                                subelementOffset = 2;
                                break;
                            case 'Проект':
                                subelementOffset = 3;
                                break;
                        }
                        const position =
                            elementHeight * subelementOffset + gapCountBeforeSection + gapHeight;
                        return position;
                    }

                    const yPos = calculateYPosition(Math.floor(index / 3), groupField);

                    // 0,045 - смещение текста по x, 0.6 - смещение текста по y
                    return [
                        // @ts-ignore
                        `${(total / yScales.y.max + 0.045) * 100}%`, // потому что для этого типа графика меняется местами x и y
                        `${yPos + 0.6}%`,
                    ];
                },
                style: {
                    textAlign: 'center',
                    fontSize: 13,
                    fill: computeFill(colorScheme),
                },
            } as Annotation;
        });

        const maxValue = data.reduce((acc: number, cur) => {
            return cur.y > acc ? cur.y : acc;
        }, 0);

        return {
            xAxis: {
                max: maxValue + 20,
                grid: {line: {style: {strokeOpacity: 0.4}}},
            },
            annotations: res,
        };
    }
};

const getFields = (settings: ChartItem['settings'], code: ChartCode) => {
    if (
        code === ChartCode.BUILDING_DATES_BY_STAGE ||
        code === ChartCode.MTR_CONSUMPTIONS ||
        code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS
    ) {
        return {
            rawFields: ['valueType', 'groupField'],
            isStack: settings?.isStack,
            groupField: 'groupField',
            pattern: (item: patternProps, color: string) => pattern(item, color),
        };
    }

    return {};
};

const getLabel = (code: ChartCode): BarConfig['label'] => {
    if (code === ChartCode.MTR_CONSUMPTIONS) {
        return {
            position: 'middle',
            layout: [
                {
                    type: 'adjust-color',
                },
            ],
        };
    }

    return false;
};

const getTooltip = (code: ChartCode) => {
    if (
        code === ChartCode.BUILDING_DATES_BY_STAGE ||
        code === ChartCode.MTR_CONSUMPTIONS ||
        code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS
    ) {
        return {
            shared: false,
            customContent: (title: string, items: unknown[]) =>
                getBarHorizontalTooltip(title, items),
        };
    }

    return undefined;
};

export const getBarConfig = (
    {settings, code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    data: BarChartProps,
): Omit<BarConfig, 'data'> => {
    const config: Omit<BarConfig, 'data'> = {
        isGroup: settings?.isGroup,
        xField: 'y',
        yField: 'x',
        seriesField: 'valueType',
        legend: {
            position: 'top-left',
            offsetY: -8,
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        xAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };

    const annotations = getAnnotations(data, code, colorScheme);
    const fields = getFields(settings, code);
    const label = getLabel(code);
    const tooltip = getTooltip(code);

    return {
        ...config,
        ...annotations,
        ...fields,
        tooltip,
        label,
    };
};

export const getColumnConfig = (
    {settings, code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    data: ColumnChartProps,
): Omit<ColumnConfig, 'data'> => {
    const config: Omit<ColumnConfig, 'data'> = {
        isGroup: settings?.isGroup,
        xField: 'x',
        yField: 'y',
        seriesField: settings?.isGroup ? 'valueType' : '',
        tooltip: {
            title: (title: string, datum: any) => {
                return datum.x.replace('\n', '');
            },
            shared: true,
        },
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        meta: {
            y: {
                alias: 'Количество',
            },
        },
        yAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };

    if (code === ChartCode.WASTE_GENERATION_ACCOUNTING_PLAN_FACT) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeFill(colorScheme),
            },
            //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
            rotate: 80.1,
            offsetX: 10,
            offsetY: 7,
        };
    }
    if (code === ChartCode.STAGE_DURATION) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeFill(colorScheme),
            },
            //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
            rotate: 80.1,
            offsetX: 8,
            offsetY: 5,
        };
        // @ts-ignore
        config.xAxis = {
            label: {
                //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
                rotate: 80.1,
                offsetX: -53,
                offsetY: -10,
            },
        };
    }

    if (code === ChartCode.COST_BY_SERVICE) {
        //@ts-ignore
        config.maxColumnWidth = 20;
        //@ts-ignore
        config.yAxis = {
            ...config.yAxis,
            top: true,
            title: {
                text: 'млн. руб',
                position: 'center',
            },
        };
    }

    if (code === ChartCode.PENETRATION_SPEED_INFO_BY_WELL) {
        //@ts-ignore
        config.color = colors;
    }

    if (code === ChartCode.OT_PB_OOS_BY_WELLS) {
        // @ts-ignore
        config.color = ({x}) => colors[data.findIndex((val) => val.x === x)];
        // @ts-ignore
        //задаем formatter потому, что свойство meta.alias при задании color перестает работать
        config.tooltip.formatter = (datum) => ({
            name: 'Количество',
            value: datum.y,
        });
    }

    if (code === ChartCode.DAYS_COUNT_BY_1000M || code === ChartCode.COST_BY_SERVICE) {
        // @ts-ignore
        config.tooltip = {
            shared: false,
        };
    }

    if ([ChartCode.OT_PB_OOS_BY_WELL, ChartCode.OT_PB_OOS_BY_WELLS].includes(code)) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: '#FFF',
                opacity: 0.7,
                font: 'Roboto',
                size: '10px',
            },
        };
        //@ts-ignore
        config.meta.y = {...config.meta.y, max: Math.max(...data.map(d => d.y)) * 1.1};
    }

    return config;
};

export const getPieConfig = (
    {code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    isFullscreen?: boolean,
): Omit<PieConfig, 'data'> => {
    const config = {
        angleField: 'value',
        colorField: 'name',
        label: {
            type: 'inner',
            // @ts-ignore
            content: (item) => item.y,
            style: {
                fill: computeFill(colorScheme),
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
    };

    // Для графика с кодом WELL_OPERATIONS_PV_NPV задаем свои цвета
    if (code === ChartCode.WELL_OPERATIONS_PV_NPV) {
        // @ts-ignore
        config.color = (d: {name: string}) => {
            const {name} = d;

            if (name.includes('НПВ')) return colorScheme === 'light' ? '#D32F2F ' : '#D32F2F';
            if (name.includes('ПВ')) return colorScheme === 'light' ? '#4CAF50' : '#388E3C';
            if (name.includes('ПНВ')) return colorScheme === 'light' ? '#F57C00' : '#EF6C00';
            return colors[3];
        };
        config.label = {
            type: 'outer',
            labelHeight: 50,
            offset: 18,
            content: (item: {name: string; percent: number; value: number}) =>
                `${item.name} ${item.value.toFixed(1)} (${(item.percent * 100).toFixed(1)} %)`,
            style: {
                fill: computeFill(colorScheme),
                // @ts-ignore
                fontSize: isFullscreen ? 18 : 12,
            },
            labelLine: {
                style: {
                    stroke: computeStrokeColor(colorScheme),
                },
            },
        };
        // @ts-ignore
        config.legend = false;
    }

    return config;
};

const prepareReflectConfig = (reflect?: ReflectConfig): LineConfig['reflect'] => {
    switch (reflect) {
        case ReflectConfig.XY:
            return ['x', 'y'];
        case ReflectConfig.X:
            return 'x';
        case ReflectConfig.Y:
            return 'y';
        default:
            return;
    }
};

const getLineConfig = (
    {code, settings}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
): Omit<LineConfig, 'data'> => {
    const isTime = [ChartCode.COST_BY_DAY, ChartCode.GEOLOGICAL_JOURNAL].some((v) => code === v);
    const config: Omit<LineConfig, 'data'> = {
        xField: 'x',
        yField: 'y',
        seriesField: 'type',
        meta: {
            x: {
                type: isTime ? 'time' : 'linear',
            },
        },
        tooltip: {
            title: (title: string, datum: any) => {
                if (isTime) {
                    if (code === ChartCode.COST_BY_DAY) {
                        return formatDate(datum.x, DT_FORMATS.defaultDate)
                    } else {
                        return formatDate(datum.x, DT_FORMATS.short);
                    }
                }
                return datum.x as string;
            },
            formatter: (item: any) => {
                return {name: item.type as string, value: item.y as string};
            },
        },
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
            maxItemWidth: 300,
        },
        interactions: [
            {
                type: 'brush',
            },
        ],
        reflect: prepareReflectConfig(settings?.reflect),
        yAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };
    // Для ГГД графика задаем свои цвета для линий т.к. в разных скважинах линии план и факт отображаются разными цветами
    if (code === ChartCode.GGD_FACT) {
        // @ts-ignore
        config.color = (d: {type: string}) => {
            const {type} = d;

            if (type.includes('Плановый забой')) return '#448AFF';
            if (type.includes('Фактический забой')) return '#4CAF50';
            if (type.includes('Отклонение от плана')) return '#7C4DFF';
            if (type.includes('ЛКС'))
                return colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.70)' : 'rgba(0, 0, 0, 0.60)';
            return '#7C4DFF';
        };
    }

    if (code === ChartCode.COST_BY_DAY) {
        //@ts-ignore
        config.yAxis = {
            ...config.yAxis,
            top: true,
            title: {
                text: 'млн. руб',
                position: 'center',
            },
        };
        //@ts-ignore
        config.interactions = [];
    }
    if (code === ChartCode.GEOLOGICAL_JOURNAL) {
        //@ts-ignore
        config.interactions = [];
    }
    if (
        code === ChartCode.TRAJECTORY_HORIZONTAL_SLICE ||
        code === ChartCode.TRAJECTORY_VERTICAL_SLICE
    ) {
        //@ts-ignore
        config.xAxis = {
            grid: {
                line: {
                    style: {
                        stroke: computeAxisLineColor(colorScheme),
                        lineWidth: 1,
                    },
                },
                visible: true,
            },
        };
    }
    return config;
};

const isPieChart = (chart: ChartProps): chart is PieChartItem[] => {
    return Array.isArray(chart) && chart.every((item) => 'name' in item && 'value' in item);
};

export const getChartData = (chart: ChartItem, props: ChartProps) => {
    if (chart.type === ChartType.Line) {
        const data: LineChartItem[] = [];

        (props as LineChartProps).forEach((line) => {
            const chartItems = getLineChartData(line?.values, line?.title);

            data.push(...chartItems);
        });

        return data;
    }

    if (
        chart.code === ChartCode.KNBK_PARAMETERS_REFERENCE ||
        chart.code === ChartCode.KNBK_SINKING_REFERENCE
    ) {
        return (props as ColumnChartProps).map((row) => {
            return {
                ...row,
                // Так как нам надо подстроить высоту названия оси под соседний график
                x: row.x.replace(/(Cкв\.)/, '\n$1'),
            };
        });
    }

    if (chart.type === ChartType.Column || chart.type === ChartType.Bar) {
        return (props as ColumnChartProps).map((row) => {
            return {
                ...row,
                x: splitStringToLines(row.x),
            };
        });
    }

    // Need to filter pie data in order to avoid label with 0
    if (chart.code === ChartCode.WELL_OPERATIONS_PV_NPV) {
        if (isPieChart(props)) {
            return props.filter((i) => i.value !== 0);
        }
    }

    return props;
};

export const getLineChartData = (data: LineChartItem[], title: string): LineChartItem[] => {
    return data.map((item) => ({...item, type: title, initialX: item.x, x: item.x}));
};

export const getChartSize = (chart: ChartItem) => {
    const customWidth: Record<string, number> = {
        // ChartCode.Параметры бурения: 12,
    };

    const customHeight: Record<string, string> = {
        [ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.MTR_CONSUMPTIONS]: '890px',
    };
    return {
        width: customWidth[chart.code] || 6,
        height: customHeight[chart.code] || '420px',
    };
};

export const computeFill = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme) {
        return colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.70)' : 'rgba(0, 0, 0, 0.60)';
    }
    return 'rgba(0, 0, 0, 0.60)';
};

const computeAxisLineColor = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme === 'dark') return 'rgba(255, 255, 255, 0.70)';
    return 'rgba(0, 0, 0, 0.10)';
};

const computeStrokeColor = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme === 'dark') return '#5E5E5E';
    return '#666666';
};

const combiningConfigs = (
    defConfig: Record<string, any>,
    config: Record<string, any>,
): Record<string, any> => {
    const result = {...defConfig};

    for (const key in config) {
        if (key in result) {
            if (typeof result[key] === 'object' && typeof config[key] === 'object') {
                result[key] = combiningConfigs(result[key], config[key]);
            } else {
                result[key] = config[key];
            }
        } else {
            result[key] = config[key];
        }
    }

    return result;
};
