import {AppBar, Button, ButtonGroup, Toolbar, useColorScheme} from '@mui/material';
import styles from './Header.module.scss';
import {useMemo, useRef} from 'react';
import UserAvatar from './UserAvatar';
import {getNavLinks} from './utils';
import {Routes} from 'constants/routes';
import {Link, NavLink, useLocation, useParams} from 'react-router-dom';
import {RouteParams, useModal} from 'utils';
import {WellSelect} from './WellSelect';
import {ColorModeToggle} from './ColorModeToggle';
import {FinalReportDialog} from '../FinalReport/FinalReportDialog';
import {AutoDrillingTabs} from '@pages/GroupedPages/autoDrilling/useTabsAutoDrilling';
import {Permission, usePermissions} from 'utils/hooks/permissions/usePermissions';
import useLogoCache from './useLogoCache';
import HeaderLogo from './HeaderLogo'; // Импортируем наш компонент

const buttonStyle = {
    '&.active': {
        color: 'text.primary',
        background: 'var(--mui-palette-buttonGroup-default)',
    },
    '&:hover:not(.active)': {
        color: 'text.primary',
    },
};

export const Header = () => {
    const {roles} = usePermissions();
    const modal = useModal();
    const reportButtonRef = useRef(null);
    const {colorScheme} = useColorScheme();
    const theme = colorScheme ? colorScheme : 'dark';
    const {logoSvg: companyLogoSvg} = useLogoCache(theme, 'company');
    const {logoSvg: proReportLogoSvg} = useLogoCache(theme, 'pro-report');
    const location = useLocation();

    const needTopControls = useMemo(() => {
        if (location.pathname === Routes.Main) {
            return false;
        }
        return [
            Routes.CommonInfo,
            Routes.Wells,
            Routes.RatesAndPrices,
            Routes.Projects,
            Routes.Prices,
            Routes.LessonsDatabase,
            Routes.StageAnalytics,
            Routes.SMBO,
            Routes.AutoDrilling,
            Routes.DrillingCarpet,
            Routes.KnbkProject,
            Routes.Monitoring,
            Routes.Administration,
        ].every((v) => !location.pathname.startsWith(v));
    }, [location.pathname]);

    const needWells = useMemo(
        () =>
            !(
                location.pathname === Routes.Main ||
                location.pathname === Routes.Wells ||
                location.pathname === Routes.WellsAnalytics ||
                location.pathname === Routes.SMBO ||
                location.pathname.includes(Routes.CommonInfo) ||
                location.pathname.includes(Routes.StageAnalytics) ||
                location.pathname.includes(Routes.Monitoring)
            ),
        [location.pathname],
    );

    const needWellHeader = useMemo(() => {
        return (
            location.pathname === Routes.Main && roles?.includes(Permission.CAN_VIEW_WELL_HEADER)
        );
    }, [location.pathname, roles]);

    const params = useParams<RouteParams>();

    const navLinks = useMemo(() => getNavLinks(params.wellId), [params.wellId]);

    const needCompanyLogo = useMemo(() => {
        return roles?.includes(Permission.CAN_VIEW_ASB_LOGO);
    }, [roles]);

    const needWellsSelect = useMemo(() => {
        if (location.pathname === Routes.Monitoring) {
            return false;
        }
        if (location.pathname.includes(Routes.Monitoring)) {
            return true;
        }
        if (needTopControls) {
            return needTopControls;
        }
        return location.pathname.includes(AutoDrillingTabs.PRO_MASTER);
    }, [location.pathname, needTopControls]);

    return (
        <>
            <AppBar
                className={styles.wrapper}
                color='transparent'
                enableColorOnDark
                sx={{
                    boxShadow: 'none',
                    width: '100%',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                }}
                variant='outlined'
                elevation={0}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Link to={Routes.Main}>
                        <HeaderLogo
                            logoSvg={proReportLogoSvg}
                            width='210px'
                            height='43px'
                        />
                    </Link>
                    <>
                        <div className={styles.wellInfoContainer}>
                            {needWells && (
                                <Link to={Routes.Wells}>
                                    <Button
                                        variant='outlined'
                                        size='large'
                                        sx={{alignSelf: 'end'}}
                                    >
                                        Все скважины
                                    </Button>
                                </Link>
                            )}
                            {needWellsSelect && <WellSelect />}
                        </div>
                        <div className={styles.wellInfoContainer}>
                            {needWellHeader && (
                                <Link to={Routes.Wells}>
                                    <Button
                                        variant='outlined'
                                        size='large'
                                        sx={{alignSelf: 'end'}}
                                    >
                                        Супервайзинг
                                    </Button>
                                </Link>
                            )}
                        </div>

                        {needTopControls && (
                            <ButtonGroup
                                variant='text'
                                color='inherit'
                                sx={{
                                    marginX: 'auto',
                                    color: 'text.secondary',
                                }}
                            >
                                {navLinks.map((item) => (
                                    <Button
                                        key={item.link}
                                        component={NavLink}
                                        to={item.link}
                                        sx={buttonStyle}
                                    >
                                        {item.name}
                                    </Button>
                                ))}
                                <Button
                                    onClick={modal.show}
                                    ref={reportButtonRef}
                                    sx={buttonStyle}
                                >
                                    Отчеты
                                </Button>
                            </ButtonGroup>
                        )}
                    </>

                    <div className={styles.settingsBlock}>
                        <ColorModeToggle />

                        {needCompanyLogo && <HeaderLogo logoSvg={companyLogoSvg} />}

                        <UserAvatar />
                    </div>
                </Toolbar>
            </AppBar>
            {modal.isVisible && <FinalReportDialog onClose={modal.hide} />}
        </>
    );
};
