import {useEffect, useState} from 'react';
import styles from './styles.module.css';
import {useParams} from 'react-router-dom';
import {RouteParams} from 'utils';
import {FiltersWell} from './filters/FiltersWell';
import {useLoadWellIncidents} from './hooks';
import {ListPopup} from './listPopup/ListPopup';
import {FiltersLimitedWells} from './filters/FiltersLimitedWells';
import {useMutation} from '@tanstack/react-query';
import {FilesService} from '@services/Files/FilesService';
import {downloadAndExtractFileExtension} from 'utils/api/file';
import {IncidentType} from '../types';

type IncidentListProps = {
    onClose: () => void;
    page: string;
    wellIdList?: string[];
    wellId?: number | string;
    tab?: IncidentType;
};

const fileService = FilesService.getInstance();

export const IncidentListPopup = ({
    onClose,
    page,
    wellIdList,
    wellId,
    tab = IncidentType.NPV,
}: IncidentListProps) => {
    const params = useParams<RouteParams>();
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedCasingColumns, setSelectedCasingColumns] = useState<string[]>([]);
    const [selectedWells, setSelectedWells] = useState<string[]>([]);
    const [incidentType, setIncidentType] = useState<IncidentType>(tab);

    useEffect(() => {
        setIncidentType(tab);
    }, [tab]);

    const {data, refetch, isLoading} = useLoadWellIncidents({
        wellId: params.wellId || wellId,
        casingColumn: selectedCasingColumns,
        wellConstructionStage: selectedStages,
        wellIdList:
            wellIdList === undefined
                ? undefined
                : selectedWells.length === 0
                  ? wellIdList
                  : selectedWells,
        incidentType: incidentType,
    });

    const downloadReportNpv = async (filters: Record<string, any>) => {
        const result = await fileService.getReportData('npv-report', {}, filters);

        fileService.downloadFile(result.id).then((file) => {
            downloadAndExtractFileExtension(file);
        });
    };

    const mutation = useMutation({
        mutationKey: ['downloadReportNpv'],
        mutationFn: (filters: Record<string, any>) => {
            return downloadReportNpv(filters);
        },
    });

    const onDownloadReportClick = () => {
        const hasSelectedWells = selectedWells.length > 0;
        mutation.mutate({
            wellIds: hasSelectedWells ? selectedWells : wellIdList ?? [params.wellId],
            datesRange: {},
            casingColumns: selectedCasingColumns,
            wellConstructionStages: selectedStages,
        });
    };

    if (!data) {
        return null;
    }

    return (
        <ListPopup
            popupType='incidents'
            onClose={onClose}
            page={page}
            data={data}
            refetch={refetch}
            isLoading={isLoading}
            className={styles.incidentsWell}
            onDownloadReportClick={onDownloadReportClick}
            incidentType={incidentType}
            setIncidentType={setIncidentType}
            filters={
                wellIdList === undefined ? (
                    <FiltersWell
                        setSelectedStages={setSelectedStages}
                        setSelectedCasingColumns={setSelectedCasingColumns}
                        selectedStages={selectedStages}
                        selectedCasingColumns={selectedCasingColumns}
                    />
                ) : (
                    <FiltersLimitedWells
                        selectedStages={selectedStages}
                        selectedWells={selectedWells}
                        setSelectedWells={setSelectedWells}
                        setSelectedStages={setSelectedStages}
                        wellIds={wellIdList}
                    />
                )
            }
        />
    );
};
