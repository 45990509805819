import {Autocomplete, Stack, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import dayjs, {Dayjs} from 'dayjs';
import {useDictionary} from 'utils/hooks/useDictionary';
import {selectDictionaries} from 'slices';
import {useSelector} from 'react-redux';
import {CustomTags} from '@features/incident/listPopups/CustomTags';
import {useKeepOpen} from 'utils/hooks/useKeepOpen';
import {DT_FORMATS} from 'utils';

type FinalReportFiltersProps = {
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
    setDateFrom: (newValue: Dayjs | null) => void;
    setDateTo: (newValue: Dayjs | null) => void;
    setSelectedStages: (newValue: string[]) => void;
    setSelectedCasingColumns: (newValue: string[]) => void;
    selectedCasingColumns: string[];
    selectedStages: string[];
};
export const FinalReportFilters = ({
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    setSelectedStages,
    setSelectedCasingColumns,
    selectedCasingColumns,
    selectedStages,
}: FinalReportFiltersProps) => {
    const constructionStageApi = 'WellConstructionStage';
    useDictionary({code: constructionStageApi});
    const casingColumnApi = 'CasingColumnName';
    useDictionary({code: casingColumnApi});
    const dictionaries = useSelector(selectDictionaries);
    const constructionStageNamesDictionary = dictionaries[constructionStageApi] || [];
    const casingColumnNamesDictionary = dictionaries[casingColumnApi] || [];
    const constructionStageNames = constructionStageNamesDictionary.map((el) => el.value);
    const casingColumnNames = casingColumnNamesDictionary.map((el) => el.value);
    return (
        <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            gap={1}
            marginBottom='8px'
        >
            <DatePicker
                value={dateFrom ? dayjs(dateFrom) : null}
                onChange={(newDate) => {
                    setDateFrom(newDate);
                }}
                slotProps={{
                    textField: {
                        size: 'small',
                        sx: {
                            width: '150px',
                            fontSize: '14px',
                            flexShrink: 0,
                        },
                    },
                    actionBar: {
                        actions: ['clear'],
                    },
                }}
                label='С'
                format={DT_FORMATS.defaultDate}
            />
            <DatePicker
                value={dateTo ? dayjs(dateTo) : null}
                onChange={(newDate) => {
                    if (newDate) {
                        setDateTo(newDate);
                    }
                }}
                slotProps={{
                    textField: {
                        size: 'small',
                        sx: {
                            width: '150px',
                            fontSize: '14px',
                            flexShrink: 0,
                        },
                    },
                    actionBar: {
                        actions: ['clear'],
                    },
                }}
                label='По'
                format={DT_FORMATS.defaultDate}
            />
            <Autocomplete
                {...useKeepOpen()}
                multiple
                sx={{
                    width: '115px',
                    '& .MuiInputBase-input': {
                        minWidth: '1px !important',
                        flexGrow: 0,
                        flexShrink: 1,
                    },
                }}
                componentsProps={{
                    popper: {
                        style: {
                            width: '200px',
                        },
                    },
                }}
                disableClearable
                options={constructionStageNames}
                onChange={(event, values) => {
                    setSelectedStages(values);
                }}
                renderTags={() => (
                    <CustomTags
                        dataLength={selectedStages.length}
                        onClear={() => setSelectedStages([])}
                    />
                )}
                value={selectedStages}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label='Этап'
                            variant='outlined'
                            size='small'
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    );
                }}
            />
            <Autocomplete
                {...useKeepOpen()}
                multiple
                sx={{width: '195px'}}
                disableClearable
                options={casingColumnNames}
                onChange={(event, values) => {
                    setSelectedCasingColumns(values);
                }}
                renderTags={() => (
                    <CustomTags
                        dataLength={selectedCasingColumns.length}
                        onClear={() => setSelectedCasingColumns([])}
                    />
                )}
                value={selectedCasingColumns}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label='Обсадная колонна'
                            variant='outlined'
                            size='small'
                        />
                    );
                }}
            />
        </Stack>
    );
};
