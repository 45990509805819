import {Stack, Typography} from '@mui/material';
import {Incident} from '../types';
import React, {useState} from 'react';
import {TripModal} from '@features/wellDesign/Modals/Trip/TripModal/TripModal';
import {IncidentInfo} from './IncidentInfo';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';

const getTypographyLineClamp = (size: 'small' | 'medium', lineCount?: number) =>
    size === 'small'
        ? {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: lineCount ? `${lineCount}` : '5',
              WebkitBoxOrient: 'vertical',
          }
        : undefined;

type IncidentDetailsProps = {
    data: Incident;
    size?: 'small' | 'medium';
    withDateLink?: boolean;
    wellId: string | number;
    openTripModal: (id: number) => void;
};

export const IncidentDetails = ({
    data,
    size = 'medium',
    withDateLink = false,
    wellId,
}: Omit<IncidentDetailsProps, 'openTripModal'>) => {
    const [tripId, setTripId] = useState<number | null>(null);
    const isServiceTypeExist = data.serviceTypes && data.serviceTypes.length > 0;
    const serviceTypeTitle = data?.serviceTypes?.map((type) => type.title).join(', ');
    return (
        <>
            <Stack
                direction='column'
                gap='20px'
            >
                <IncidentInfo
                    size={size}
                    data={data}
                    wellId={wellId}
                    withDateLink={withDateLink}
                    openTripModal={setTripId}
                />
                <Stack
                    direction='column'
                    gap='6px'
                >
                    <Typography
                        variant='caption'
                        color='var(--mui-palette-text-secondary)'
                    >
                        Описание и причины возникновения
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={getTypographyLineClamp(size)}
                    >
                        {data.description || 'Данные отсутствуют'}
                    </Typography>
                </Stack>
                <Stack
                    direction='column'
                    gap='6px'
                >
                    <Typography
                        variant='caption'
                        color='var(--mui-palette-text-secondary)'
                        sx={getTypographyLineClamp(size)}
                    >
                        Принятые меры по ликвидации
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={getTypographyLineClamp(size)}
                    >
                        {data.takenAction || 'Данные отсутствуют'}
                    </Typography>
                </Stack>
                <Stack>
                    <Typography variant='subtitle1'>Виновная сторона</Typography>
                    <Stack
                        direction='row'
                        flexGrow={1}
                    >
                        <Stack
                            direction='column'
                            gap='4px'
                            sx={{width: '50%'}}
                        >
                            <Typography
                                variant='caption'
                                color='var(--mui-palette-text-secondary)'
                            >
                                Тип сервиса
                            </Typography>
                            <LightTooltip
                                title={
                                    size === 'small'
                                        ? isServiceTypeExist
                                            ? serviceTypeTitle
                                            : ''
                                        : ''
                                }
                                enterDelay={500}
                            >
                                <Typography
                                    variant='body1'
                                    sx={getTypographyLineClamp('small', size === 'small' ? 1 : 3)}
                                >
                                    {isServiceTypeExist ? serviceTypeTitle : 'Данные отсутствуют'}
                                </Typography>
                            </LightTooltip>
                        </Stack>
                        <Stack
                            direction='column'
                            gap='4px'
                            sx={{width: '50%'}}
                            ml={2}
                        >
                            <Typography
                                variant='caption'
                                color='var(--mui-palette-text-secondary)'
                            >
                                Организация
                            </Typography>
                            <LightTooltip
                                title={size === 'small' ? data.guiltySide : ''}
                                enterDelay={500}
                            >
                                <Typography
                                    variant='body1'
                                    sx={getTypographyLineClamp('small', size === 'small' ? 1 : 3)}
                                >
                                    {data.guiltySide || 'Данные отсутствуют'}
                                </Typography>
                            </LightTooltip>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            {tripId !== null && (
                <TripModal
                    tripId={tripId}
                    onClose={() => setTripId(null)}
                    aboveModal
                />
            )}
        </>
    );
};
