import {PageSchema, ParamsType} from 'utils';
import api, {instance} from 'utils/api';
import {
    AdditionalWorkByWellResponse,
    CreateFileResponse,
    Incident,
    IncidentType,
    IncidentsByFieldResponse,
    IncidentsByWellResponseGrouped,
    PnvByWellResponse,
} from '@features/incident/types';
import {AxiosProgressEvent} from 'axios';
import {PaginatedResponse} from 'types/pagination';
import {IIncidentsListItem} from '@features/operations/types';

function deleteData(params: ParamsType): Promise<unknown> {
    return api.delete('/npv', undefined, undefined, params);
}

function getData(params: ParamsType) {
    return api.get<PaginatedResponse<Incident[]>>(`/npv`, params);
}

function getIncident(id: string | number) {
    return api.get<Incident>(`/npv/${id}`);
}

function createData(data: Record<string, any>, params: ParamsType): Promise<Incident> {
    return api.post('/npv', data, {}, params);
}

function getPageSchema(): Promise<PageSchema> {
    return api.get(`/npv/table/schema`);
}

function editData(data: Record<string, any>, params: ParamsType): Promise<Incident> {
    return api.put('/npv', data, undefined, params);
}

function getIncidentsByWell(params: {
    wellId: number | string;
    casingColumn?: string[];
    wellConstructionStage?: string[];
    type?: IncidentType;
}): Promise<IncidentsByWellResponseGrouped> {
    return api.get(`/table/dashboard/field-common-info/incidentsByWell`, params, undefined, params);
}

function getPnvByWell(params: {
    wellId: number | string;
    casingColumn?: string[];
    wellConstructionStage?: string[];
}): Promise<PnvByWellResponse> {
    return api.get(`/table/dashboard/field-common-info/pnvByWell`, params, undefined, params);
}

function getAdditionalWorkByWell(params: {
    wellId: number | string;
    casingColumn?: string[];
    wellConstructionStage?: string[];
}): Promise<AdditionalWorkByWellResponse> {
    return api.get(
        `/table/dashboard/field-common-info/additionalWorksByWell`,
        params,
        undefined,
        params,
    );
}

function getIncidentsByWells(params: {
    wellId: string[];
    wellConstructionStage?: string[];
    type?: IncidentType;
}): Promise<IncidentsByWellResponseGrouped> {
    return api.get(
        '/table/dashboard/field-common-info/incidentsByWells',
        params,
        undefined,
        params,
    );
}

function getIncidentsByField(params: {
    field: string;
    wellId?: string[];
    wellConstructionStage?: string[];
    type?: IncidentType;
}): Promise<IncidentsByFieldResponse> {
    return api.get(
        `/table/dashboard/field-common-info/incidentsByField`,
        params,
        undefined,
        params,
    );
}

function getIncidentTitleList(params: ParamsType) {
    return api.get<IIncidentsListItem[]>(`/npv/titles`, params);
}

function createFile(
    data: FormData,
    params: ParamsType,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    signal?: AbortSignal,
): Promise<CreateFileResponse> {
    // Явно используем instance, потому что api.post переопределяет ContentType и файл уходит на сервер некорректно
    return instance({
        method: 'POST',
        url: `/files/upload`,
        data,
        params,
        onUploadProgress,
        signal,
    });
}

export const IncidentService = {
    deleteData,
    getData,
    createData,
    createFile,
    editData,
    getIncident,
    getPageSchema,
    getIcindentsByWell: getIncidentsByWell,
    getIncidentsByWells,
    getPnvByWell,
    getAdditionalWorkByWell,
    getIcindentsByField: getIncidentsByField,
    getIncidentsList: getIncidentTitleList,
};
