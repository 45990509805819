import {IconButton, Stack, Typography} from '@mui/material';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import styles from './styles.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ListPopupNode, IncidentsByFieldSummary} from '@features/incident/types';
import {colors} from '../constants';
import {CommonAccordionSummary} from './listPopup/CommonAccordionSummary';
import {ListPopupSummaryRow} from './listPopup/ListPopupSummaryRow';
import {accordionStyles} from './utils';

type FieldSummaryAccoridonType = {
    summary: IncidentsByFieldSummary;
    idx: number;
    selectedIncident: ListPopupNode | null;
    setSelectedIncident: (incident: ListPopupNode) => void;
};
export const FieldSummaryAccoridon: React.FC<FieldSummaryAccoridonType> = ({
    summary,
    idx,
    selectedIncident,
    setSelectedIncident,
}) => (
    <Accordion sx={accordionStyles}>
        <CommonAccordionSummary
            idx={idx}
            summary={summary}
        />
        <AccordionDetails
            sx={{
                padding: '0',
            }}
        >
            {summary.incidentsByWell.map((incidentByField) => (
                <Accordion
                    sx={accordionStyles}
                    key={incidentByField.title}
                >
                    <AccordionSummary
                        expandIcon={
                            <IconButton size='small'>
                                <ExpandMoreIcon fontSize='small' />
                            </IconButton>
                        }
                        sx={{
                            '& .MuiAccordionSummary-content': {
                                margin: 0,
                            },
                            borderLeft: `5px solid ${colors[idx]}`,
                            minHeight: 0,
                        }}
                        className={styles.listNodeRow}
                    >
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            width='100%'
                            alignItems='center'
                        >
                            <Stack
                                direction='row'
                                gap='4px'
                                alignItems='center'
                                padding='2px 0'
                            >
                                <Typography
                                    variant='subtitle1'
                                    sx={{lineHeight: '20px'}}
                                >
                                    {incidentByField.title}
                                </Typography>
                            </Stack>
                            {Boolean(incidentByField.durationSumDays) && (
                                <Typography
                                    variant='body2'
                                    sx={{opacity: '.88'}}
                                >{`${
                                    Math.ceil(incidentByField.durationSumDays * 100) / 100
                                }\u00A0сут`}</Typography>
                            )}
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails
                        key={`${summary.title}_${incidentByField.title}_${idx}`}
                        sx={{borderLeft: `5px solid ${colors[idx]}`, padding: '0'}}
                    >
                        <Stack direction='column'>
                            {incidentByField.incidents.map((incident) => (
                                <ListPopupSummaryRow
                                    key={incident.id}
                                    node={incident}
                                    selectedNode={selectedIncident}
                                    setSelectedNode={setSelectedIncident}
                                    summaryTitle={`${summary.title}_${incidentByField.title}`}
                                />
                            ))}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            ))}
        </AccordionDetails>
    </Accordion>
);
