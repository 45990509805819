import {useQuery} from '@tanstack/react-query';
import {useCallback} from 'react';
import api from 'utils/api';

export enum Permission {
    Projects = 'CAN_VIEW_PROJECTS',
    Wells = 'CAN_VIEW_WELLS',
    canEditWells = 'CAN_EDIT_WELLS',
    canEditPads = 'CAN_EDIT_WELL_PADS',
    canEditFields = 'CAN_EDIT_WELL_FIELDS',
    Prices = 'CAN_VIEW_PRICES',
    CommonInfo = 'CAN_VIEW_COMMON_INFO',
    CanViewIncidentAnalyse = 'CAN_VIEW_INCIDENT_ANALYSE',
    CanViewAutoDrilling = 'CAN_VIEW_AUTO_DRILLING',
    CAN_VIEW_MONITORING = 'CAN_VIEW_MONITORING',
    CAN_VIEW_DRILLING_RIG = 'CAN_VIEW_DRILLING_RIG',
    CAN_VIEW_WELL_HEADER = 'CAN_VIEW_WELL_HEADER',
    CAN_VIEW_ASB_LOGO = 'CAN_VIEW_ASB_LOGO',
    CAN_VIEW_DRILLING_CARPET='CAN_VIEW_DRILLING_CARPET',
    CAN_VIEW_KNBK_IN_EXTERNAL_WELL = 'CAN_VIEW_KNBK_IN_EXTERNAL_WELL',
    CAN_VIEW_DRILLING_EFFICIENCY= 'CAN_VIEW_DRILLING_EFFICIENCY',
    CAN_VIEW_ADMIN_PANEL= 'CAN_VIEW_ADMIN_PANEL',
}

export const usePermissions = () => {
    const getPermissions: () => Promise<Permission[]> = useCallback(() => {
        return api.get(`/me/permissions`);
    }, []);
    const {data: roles, isLoading} = useQuery({
        queryKey: ['permissions'],
        queryFn: getPermissions,
        staleTime: 8 * (60 * 1000), // 8 mins
        gcTime: 10 * (60 * 1000), // 10 mins
    });

    return {roles, isLoading};
};
