import {FileEntity} from '@pages/FilesPage';
import {DictionaryItem} from 'utils';

export enum NpvFileType {
    ACT_NPV = 'ACT_NPV',
    ACT_INCIDENT_START = 'ACT_INCIDENT_START',
    ACT_TECHNICAL_INVISTIGATION = 'ACT_TECHNICAL_INVISTIGATION',
    ACT_MTR_CONSUMPTION = 'ACT_MTR_CONSUMPTION',
    PHOTO = 'PHOTO',
    OTHER = 'OTHER',
}

export enum NpvStatus {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
}

export enum DocumentStatus {
    WITH_DOC = 'WITH_DOC',
    WITHOUT_DOC = 'WITHOUT_DOC',
    WITHOUT_ACT = 'WITHOUT_ACT',
}

export type IncidentFile = FileEntity & {
    type: NpvFileType;
};

export type CreateFileResponse = {
    data: {
        id: string;
        fileSize: number;
        contentType: string;
        originalFilename: string;
        groupId: string;
        wellId: string;
        date: string;
    };
};

export enum IncidentType {
    NPV = 'NPV',
    PNV = 'PNV',
    ADDITIONAL_WORK = 'ADDITIONAL_WORK',
}

export type Incident = {
    id: string;
    workType: string;
    type: IncidentType;
    title: string;
    fullTitle: string;
    startTime: string;
    endTime: string;
    duration: number; // hours
    casingColumn: string;
    wellbore: string;
    wellConstructionStage: string;
    endDepth: number;
    startDepth: number;
    description: string;
    takenAction: string;
    guiltySide: string;
    status: NpvStatus;
    files?: IncidentFile[] | null;
    photos?: IncidentFile[] | null;
    wellId: number;
    run?: number;
    runId?: number;
    serviceTypeCodes?: string[];
    serviceTypes?: DictionaryItem[];
    isWithoutAct?: boolean;
    comment?: string;
    _links: {
        self: {
            href: string;
        };
        npvEntity: {
            href: string;
        };
    };
};

export type ListPopupNode = {
    title: string;
    durationHours: number;
    id: number | string;
    disableSelection?: boolean;
};

export type ListPopupSummary = {
    title: string;
    durationSumDays: number;
    durationSumPercent: number;
    incidents: ListPopupNode[];
};

export type IncidentsByWellInField = {
    title: string;
    durationSumDays: number;
    incidents: ListPopupNode[];
    casingColumn: string;
};

export type IncidentsByFieldSummary = {
    title: string;
    durationSumDays: number;
    durationSumPercent: number;
    incidentsByWell: IncidentsByWellInField[];
};

export type IncidentsByWellResponse = {
    summaries: ListPopupSummary[];
};

export type ListPopupSummaryGrouped = {
    title: string;
    summaries: ListPopupSummary[];
};

export type IncidentsByWellResponseGrouped = {
    summaries: ListPopupSummaryGrouped[];
};

export type DashboardPnv = {
    workType: string;
    casingColumn: string;
    durationHours: number;
};

export type PnvByWellSummary = {
    workCategory: string;
    durationSumDays: number;
    durationSumPercent: number;
    pnvs: DashboardPnv[];
};

export type DashboardAdditionalWork = {
    workType: string;
    casingColumn: string;
    durationHours: number;
};

export type AdditionalWorkByWellSummary = {
    workCategory: string;
    durationSumDays: number;
    durationSumPercent: number;
    additionalWorks: DashboardAdditionalWork[];
};

export type AdditionalWorkByWellResponse = {
    summaries: AdditionalWorkByWellSummary[];
};

export type PnvByWellResponse = {
    summaries: PnvByWellSummary[];
};

export type IncidentsByFieldResponse = {
    summaries: ListPopupSummary[];
};
