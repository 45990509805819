import {Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import {Incident, NpvFileType} from '../types';
import React from 'react';
import {IncidentFileRow} from './IncidentFileRow';

type IncidentFilesProps = {
    data: Incident;
};
export const IncidentFiles: React.FC<IncidentFilesProps> = ({data}) => {
    if (data.isWithoutAct) {
        return (
            <Stack direction='column'>
                <FormControlLabel
                    label='Без акта'
                    control={
                        <Checkbox
                            size='medium'
                            checked
                            disabled
                        />
                    }
                />
                <Stack
                    direction='column'
                    gap='16px'
                >
                    <Stack
                        direction='column'
                        gap='6px'
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                        >
                            Комментарий
                        </Typography>
                        <Typography variant='regular1'>
                            {data.comment || 'Данные отсутствуют'}
                        </Typography>
                    </Stack>
                    <IncidentFileRow
                        files={data.photos}
                        type={NpvFileType.PHOTO}
                    />
                </Stack>
            </Stack>
        );
    }
    return (
        <Stack direction='column'>
            {([...(data.files || []), ...(data.photos || [])]).length === 0 ? (
                <Typography
                    variant='subtitle2'
                    fontWeight={400}
                    color='var(--mui-palette-text-secondary)'
                >
                    Нет загруженных документов
                </Typography>
            ) : (
                Object.values(NpvFileType).map((type) => (
                    <IncidentFileRow
                        key={type}
                        files={type === NpvFileType.PHOTO ? data.photos : data.files}
                        type={type}
                    />
                ))
            )}
        </Stack>
    );
};
