import {FormControlLabel, Switch, useColorScheme} from '@mui/material';
import {useEffect} from 'react';
import {useUserSettings} from 'utils';

export const ColorModeToggle = () => {
    const {settings: themeSettings, changeSettings} = useUserSettings('themeSettings');
    const {setMode, mode} = useColorScheme();

    useEffect(() => {
        if (!themeSettings) return;
        setMode(themeSettings.theme);
    }, [setMode, themeSettings]);

    const handleColorModeChange = () => {
        const theme = mode === 'light' ? 'dark' : 'light'
        setMode(theme);
        changeSettings({...themeSettings, theme})
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={mode === 'dark'}
                    onChange={handleColorModeChange}
                />
            }
            label='Темная тема'
            sx={{
                marginRight: '0px',
                '& .MuiFormControlLabel-label': {
                    fontSize: 14,
                    color: 'var(--mui-palette-text-secondary)',
                },
            }}
        />
    );
};
