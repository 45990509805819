import {GridColDef, GridRowParams, GridSortModel} from '@mui/x-data-grid';
import {PaginationModel} from '../../../types/pagination';

/** Результат ответа по запросу таблицы */
export type TimeBalanceTableResponse = PaginationModel & {
    content: TimeBalanceTableItem[];
};

/** Результат ответа по запросу данных конкретной даты */
export type TimeBalanceAnalyticResponse = {
    date: string;
    result: AnalyticResultItem[];
    drilling: AnalyticItem[];
    fastening: AnalyticItem[];
    npt: AnalyticNptItem[];
};

export type TimeBalanceTableItem = {
    id: string;
    date: string;
    slaughter: number | string;
    penetration: number;
    accumulateTime: number | string;
    npt: number;
    pt: number;
};

/** Чтобы отображать на фронте статус */
export enum DifferenceStatus {
    // зеленая рамка
    Normal,
    // оранжевая
    Warning,
    // красная
    Alert,
}

export type AnalyticNptItem = {
    name: string;
    measure: string;
    value: number;
};

export type AnalyticResultItem = AnalyticNptItem & {
    difference: number;
    status: DifferenceStatus;
};

export type AnalyticItem = {
    name: string;
    hours: number;
    percents: number;
};

export type TimeBalanceAnalyticProps = {
    selectedDateItem: TimeBalanceTableItem;
};

export type IndicatorPanelItem = AnalyticNptItem &
    (
        | {
              difference: number;
              status: DifferenceStatus;
          }
        | {
              difference?: never;
              status?: never;
          }
    );

export type IndicatorPanelProps = {
    title: string;
    items: IndicatorPanelItem[];
};

export type ParametersListProps = {
    title: string;
    items: AnalyticItem[];
};

export type TimeBalanceTableProps = {
    onRowClick: (params: GridRowParams<TimeBalanceTableItem>) => void;
};

export type TimeBalanceTableContentProps = {
    onRowClick: (params: GridRowParams<TimeBalanceTableItem>) => void;
    onSortChange: (model: GridSortModel) => void;
    columns: GridColDef<TimeBalanceTableItem>[];
    data?: TimeBalanceTableItem[];
    sort?: GridSortModel;
};

export type TimeBalanceAnalyticContentProps = {
    data?: TimeBalanceAnalyticResponse;
    loading: boolean;
};
