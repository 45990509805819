import {DatePicker, Input, InputNumber} from 'antd';
import dayjs from 'dayjs';

import {TableColumn} from 'components';
import {PageSchema, PageData, formatDate} from 'utils';
import {FileCell} from '../../features/files/FileCell';
import {ColumnData} from 'components/NewTable/types';

export type TypeFormatters<T> = {
    preFormatter: (value: unknown) => T;
    postFormatter: (value?: T) => unknown;
    chartRender: (value?: T) => string | number;
    formInput: any; // Компонент
    columnSettings: TableColumn<T>;
};

export const TYPE_FORMATTERS = {
    datetime: {
        preFormatter: (value) =>
            !Number.isNaN(Date.parse(String(value))) ? dayjs(String(value)) : null,
        postFormatter: (value) => formatDate(value, 'utcShortTime'),
        chartRender: (value) => formatDate(value, 'full'),
        formInput: DatePicker,
        columnSettings: {
            render: (value) => formatDate(value),
        },
    } as TypeFormatters<dayjs.Dayjs>,
    date: {
        preFormatter: (value) =>
            !Number.isNaN(Date.parse(String(value))) ? dayjs(String(value)) : null,
        postFormatter: (value) => formatDate(value, 'utcShortTime'),
        chartRender: (value) => formatDate(value, 'fullDate'),
        formInput: DatePicker,
        columnSettings: {
            render: (value) => formatDate(value, 'fullDate'),
        },
    } as TypeFormatters<dayjs.Dayjs>,
    number: {
        preFormatter: (value) => (value || value === 0 ? Number(value) : ''),
        postFormatter: (value) => value,
        chartRender: (value) => value,
        formInput: InputNumber,
        columnSettings: {
            render: (value) => (value || value === 0 ? value.toFixed(2) : ''),
        },
    } as TypeFormatters<number>,
    string: {
        preFormatter: (value) => String(value || ''),
        postFormatter: (value) => value,
        chartRender: (value) => value,
        formInput: Input,
        columnSettings: {
            render: (value) => value,
        },
    } as TypeFormatters<string>,
    file: {
        preFormatter: (value) => String(value || ''),
        postFormatter: (value) => value,
        chartRender: (value) => value,
        formInput: Input,
        columnSettings: {
            // TODO: исправить типы
            render: (value, record) => <FileCell record={record as any} />,
        },
    } as TypeFormatters<string>,
    boolean: {
        preFormatter: (value) => value,
        postFormatter: (value) => (value === undefined ? null : Boolean(value)),
        chartRender: (value) => String(value),
        formInput: Input,
        columnSettings: {
            render: (value) => (value === true ? 'Да' : value === false ? 'Нет' : ''),
        },
    } as TypeFormatters<boolean>,
} as const;

export type ColumnTypeName = keyof typeof TYPE_FORMATTERS;

export const DEFAULT_TYPE: ColumnTypeName = 'string';

export const getColumnTypeName = (column: ColumnData): ColumnTypeName => {
    switch (column?.type) {
        case 'number':
            return 'number';
        case 'string':
            switch (column.format) {
                case 'date-time':
                    return 'datetime';
                case 'date':
                    return 'date';
                default:
                    return 'string';
            }
        case 'file':
            return 'file';
        case 'boolean':
            return 'boolean';
        default:
            return DEFAULT_TYPE;
    }
};

export type BaseData<K extends string | symbol | number = string, V = any> = Record<K, V> & {
    _links: {
        self: {href: string};
    };
};

export const prepareTableData = <T extends BaseData>(
    pageData: PageData<T>,
    pageSchema: PageSchema,
) => {
    if (!pageData?._embedded) return [];

    const rawData = Object.values(pageData._embedded).flat() as T[];
    const out = [];

    for (let i = 0; i < rawData.length; i++) {
        // NB! у файлов нет self ссылки (бак бека?)
        const id = rawData[i]._links?.self?.href || rawData[i]?.id;
        const row: Record<'id' | keyof T, any> = {
            ...rawData[i],
            id,
            key: id,
        };

        if (pageSchema?.properties) {
            for (const key in rawData[i]) {
                if (!(key in pageSchema.properties)) continue;
                const columnType = getColumnTypeName(pageSchema.properties[key]);
                row[key] = TYPE_FORMATTERS[columnType].preFormatter(rawData[i][key]);
            }
        }

        out.push(row);
    }

    return out;
};
